import {useAppSelector} from "../../../hooks";
import {useEffect, useState} from "react";
import {get} from "../../../api";
import {LiveEvent} from "../../../types/event";
import {HOST} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {Box, Stack, Typography} from "@mui/joy";
import LiveEventItemComponent from "./LiveEventItemComponent";
import {Subscription} from "../../../types/subscription";
import liveEventTexts from "./liveEventTexts";

const LiveEventPage = () => {
    const userSelector = useAppSelector(state => state.user)
    const langSelector = useAppSelector(state => state.lang)
    const navigate = useNavigate()
    const [events, setEvents] = useState<LiveEvent[]>([])

    useEffect(() => {
        get<LiveEvent[]>(HOST + "/event", {}).then(value => setEvents(value))
            .catch(reason => {
                console.log("Unable to fetch events: " + reason)
            })

    }, [])

    return <Box component={"div"} display={"flex"} flexDirection={"column"}
                paddingX={{xs: "25px", lg: "15%"}} marginTop={{xs: 8.5, sm: 10}}
                gap={2} boxSizing={"border-box"} height={"100%"}>

        <Stack gap={3} marginY={8} paddingBottom={2} boxSizing={"border-box"} id={"subscription"}>

            <Stack gap={0.5}>
                <Typography textAlign={"start"} level={"h2"}>
                    Live Event
                </Typography>
                <Typography textAlign={"start"} level={"body-md"}>
                    {liveEventTexts.title[langSelector.lang]}
                </Typography>
                <Typography textAlign={"start"} level={"body-md"}>
                    {liveEventTexts.description[langSelector.lang]}
                </Typography>

            </Stack>
            <Stack>
                {events.map(value =>
                    <LiveEventItemComponent
                        onSetIframeSrc={src => {
                            navigate(`/live-meeting?url=${src}`)
                        }}
                        event={value}/>)}
            </Stack>
        </Stack>

    </Box>
}

export default LiveEventPage