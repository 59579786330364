import {Box, Stack, Typography} from "@mui/joy";
import "./styles.css"
import Article from "../../types/article";
import {useAppSelector} from "../../hooks";

type LessFeaturedStoriesComponentProps = {
    article: Article
}

const LessFeaturedStoriesComponent = (props: LessFeaturedStoriesComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitle = langSelector.lang === "en" ? props.article.title : (props.article.title_fr || props.article.title)
    return <a href={`/articles/${props.article.id}`} className={"text-normal"}>
        <Stack direction={{xs: "column", lg: "row"}} gap={2}>
            <Box component={"div"} width={{xs: "100%", xl: "max-content"}}>
                <Box component={"img"} src={props.article.images?.[0]?.[0]}
                     className={"less-featured-img"} width={{xs: "100%", lg: 300}}
                     height={{xs: 250, md: 350, lg: 220}}/>
            </Box>
            <Typography level={"title-md"} textAlign={"start"}>
                {articleTitle}
            </Typography>
        </Stack>
    </a>
}

export default LessFeaturedStoriesComponent