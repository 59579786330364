import {
    Box,
    Button,
    Card,
    CardActions,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemDecorator,
    Stack,
    Typography
} from "@mui/joy";
import {Check, KeyboardArrowRight} from "@mui/icons-material";
import {
    PLAN_ACCESS_MONTHLY,
    PLAN_ACCESS_TERMLY,
    PLAN_ACCESS_YEARLY,
    PLAN_ADVANCED_MONTHLY, PLAN_ADVANCED_TERMLY, PLAN_ADVANCED_YEARLY,
    PLAN_PREMIUM_MONTHLY,
    PLAN_PREMIUM_TERMLY,
    PLAN_PREMIUM_YEARLY
} from "../../Subscribe/constants";
import myAccountTexts from "../MyAccount/myAccountTexts";
import {useAppSelector} from "../../../hooks";
import subscribeTexts from "./subscriptionTexts";

const SubscriptionPage = () => {
    const languageSelector = useAppSelector(state => state.lang)
    return <Box component={"div"} display={"flex"} flexDirection={"column"}
                paddingX={{xs: "25px", lg: "15%"}} marginTop={{xs: 8.5, sm: 10}}
                gap={2} boxSizing={"border-box"}>

        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} gap={5} marginY={8}
             paddingBottom={2} boxSizing={"border-box"} id={"subscription"}>
            <Stack gap={1}>
                <Typography level="h2" textAlign={"start"}>
                    {subscribeTexts.subscribeTitle[languageSelector.lang]}
                </Typography>
                <Typography level="body-md" textAlign={"start"}>
                    {subscribeTexts.subscriptionDescription[languageSelector.lang]}
                </Typography>
            </Stack>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: "center",
                    gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
                    gap: 2,
                }}>
                <Card
                    size="lg"
                    variant="solid"
                    color="neutral"
                    invertedColors
                    sx={{bgcolor: 'primary.50'}}>
                    <Chip size="sm" variant="outlined">
                        BASIC
                    </Chip>
                    <Typography level="h2">Access</Typography>
                    <Divider inset="none"/>
                    <List
                        size="sm" sx={{mx: 'calc(-1 * var(--ListItem-paddingX))'}}>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.basicPlanFunctionality1[languageSelector.lang]}
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.basicPlanFunctionality2[languageSelector.lang]}
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.basicPlanFunctionality3[languageSelector.lang]}
                        </ListItem>
                    </List>
                    <Divider inset="none"/>
                    <CardActions>
                        <a href={"/subscribe?plan=" + PLAN_ACCESS_MONTHLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                5,40 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.monthlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                        <a href={"/subscribe?plan=" + PLAN_ACCESS_TERMLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                13,50 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.termlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                        <a href={"/subscribe?plan=" + PLAN_ACCESS_YEARLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                54,00 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.yearlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                    </CardActions>
                </Card>


                <Card
                    size="lg"
                    variant="solid"
                    color="neutral"
                    invertedColors
                    sx={{bgcolor: 'primary.200'}}>
                    <Chip size="sm" variant="outlined">
                        MOST POPULAR
                    </Chip>
                    <Typography level="h2">Advanced</Typography>
                    <Divider inset="none"/>
                    <List
                        size="sm"
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            mx: 'calc(-1 * var(--ListItem-paddingX))',
                        }}>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.advancedPlanFunctionality1[languageSelector.lang]}
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.advancedPlanFunctionality2[languageSelector.lang]}
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.advancedPlanFunctionality3[languageSelector.lang]}
                        </ListItem>
                    </List>
                    <Divider inset="none"/>

                    <CardActions>
                        <a href={"/subscribe?plan=" + PLAN_ADVANCED_MONTHLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                11,55 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.monthlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                        <a href={"/subscribe?plan=" + PLAN_ADVANCED_TERMLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                28,87 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.termlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                        <a href={"/subscribe?plan=" + PLAN_ADVANCED_YEARLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                115,50 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.yearlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                    </CardActions>
                </Card>


                <Card
                    size="lg"
                    variant="solid"
                    color="neutral"
                    invertedColors
                    sx={{bgcolor: 'primary.600'}}>
                    <Chip size="sm" variant="outlined">
                        MOST VALUABLE
                    </Chip>
                    <Typography level="h2">Premium</Typography>
                    <Divider inset="none"/>
                    <List
                        size="sm"
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            mx: 'calc(-1 * var(--ListItem-paddingX))',
                        }}>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                             {subscribeTexts.premiumFunctionality1[languageSelector.lang]}
                        </ListItem>
                        <ListItem>
                            <ListItemDecorator>
                                <Check/>
                            </ListItemDecorator>
                            {subscribeTexts.premiumFunctionality2[languageSelector.lang]}
                        </ListItem>
                    </List>
                    <Divider inset="none"/>

                    <CardActions>
                        <a href={"/subscribe?plan=" + PLAN_PREMIUM_MONTHLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                22,30 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.monthlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                        <a href={"/subscribe?plan=" + PLAN_PREMIUM_TERMLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                55,75 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.termlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>

                        <a href={"/subscribe?plan=" + PLAN_PREMIUM_YEARLY.label} className={"text-normal"}>
                            <Typography level="title-lg" sx={{mr: 'auto'}}>
                                223,00 €{' '}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                    / {subscribeTexts.yearlyLabel[languageSelector.lang]}
                                </Typography>
                            </Typography>
                        </a>
                    </CardActions>
                </Card>


            </Box>
        </Box>
    </Box>
}

export default SubscriptionPage