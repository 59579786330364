import {Box, Button, Stack, Typography, useTheme} from "@mui/joy";
import SxStyles from "./SxStyles";
import {useState} from "react";
import Article from "../../types/article";
import "./styles.css"
import {useAppSelector} from "../../hooks";
import homeTexts from "../../pages/Main/Home/homeTexts";


type TrendingNewsComponentProps = {
    articles: Article[]
}

const TrendingNewsComponent = (props: TrendingNewsComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const theme = useTheme()
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContent = (props.articles?.[0][articleContentField] || props.articles?.[0].content)?.substring(0, 500) + "..."


    return <Box component={'div'} className={"trending-news-container"} marginTop={{xs: 8.5, sm: 14}}
                height={{xs: "42rem", md: "40rem", lg: "35rem"}} width={"100%"}>
        <img src={props.articles?.[0].images?.[0]?.[0]} className={"trending-news-img"}/>

        <Box component={"div"} sx={SxStyles.trendingNewsContainerOverlay}>
            <Stack gap={3}>
                <Typography textAlign={"start"} sx={SxStyles.text} level={"h2"}>
                    {props.articles?.[0][articleTitleField] || props.articles?.[0].title}
                </Typography>

                <a href={"/articles/" + props.articles?.[0].id}
                   style={{
                       display: "flex",
                       alignItems: "start",
                       textDecoration: "none",
                       width: "max-content",
                       marginTop: "15px"
                   }}>
                    <Button color={"primary"} size={'lg'}>{homeTexts.readTrendingNewsLabel[langSelector.lang]}</Button>
                </a>
            </Stack>
        </Box>
    </Box>
}

export default TrendingNewsComponent
