import {useEffect, useState} from "react";
import {Advertisement} from "../../types/advertisement";
import {get} from "../../api";
import {HOST} from "../../constants";
import {Box, Typography} from "@mui/joy";
import {InfoOutlined} from "@mui/icons-material";

const HorizontalRectangleAdvertisementComponent = () => {
    const [ads, setAds] = useState<Advertisement[]>([])
    const currentAd = ads.find(value => value.type === "HORIZONTAL_RECTANGLE")
    useEffect(() => {
        get<Advertisement[]>(HOST + "/current-advertisement").then(value => setAds(value))
            .catch(reason => {
                console.log(reason)
            })
    }, [])

    return <Box href={currentAd?.url} component={'a'} width={"100%"} height={200}
                sx={{textDecoration: "none"}} position={"relative"}>

        <Box component={"img"} src={currentAd?.media_path} width={"100%"} height={200} sx={{objectFit: "cover"}}/>

        <Box component={"div"} display={"flex"} position={"absolute"} left={0} top={0} paddingX={1}
             sx={{backgroundColor: "grey"}} alignItems={"center"}>
            <Typography level={"body-xs"} sx={{color: "white"}}
                        startDecorator={<InfoOutlined sx={{fontSize: "small"}}/>}>Advertisement</Typography>
        </Box>

    </Box>
}

export default HorizontalRectangleAdvertisementComponent