export const contactUsTexts = {
    contactUsTitle: {
        en: "Let's talk",
        fr: "Discutons"
    },
    contactUsDescription: {
        en: "We love your feedback, suggestion, service request and review on how to serve you. we always listen",
        fr: "Nous apprecirons avoir vos suggestions, requete de service et evalutation sur comment vous fournir les meilleurs services. Nous sommes toujours à l'écoute"
    },
    serviceRequestQuestion: {
        en: "What service do you need?",
        fr: "Quel service desirez-vous?",
    },
    contactUsMessagePlaceholder: {
        en: "How can we help? Write us a message.",
        fr: "Comment pouvons-nous aider ? écrivez un message",
    },
    contactUsSuccessLabel: {
        en: "Thanks for your feedback, we will get back to you very soon.",
        fr: "Merci pour votre retour, nous vous contacterons très bientôt.",
    },
    contactUsSubmitLabel: {
        en: "Send message",
        fr: "Envoyer",
    },
}

export default contactUsTexts