import {Box, Grid, Stack, Typography} from "@mui/joy";
import {ArticleComponent1} from "../ArticleComponent";
import FeaturedCategoryItemComponent from "./FeaturedCategoryItemComponent";
import Article from "../../types/article";
import Category from "../../types/category";
import {useAppSelector} from "../../hooks";
import homeTexts from "../../pages/Main/Home/homeTexts";

type FeaturedCategoriesComponentProps = {
    categories: {
        category: Category,
        feature_news: Article[]
    }[]
}

const FeaturedCategoriesComponent = (props: FeaturedCategoriesComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    return <Stack gap={2} height={"max-content"} paddingY={2}>
        <Typography textTransform={"uppercase"} color={"primary"}
                    textAlign={"start"}>{homeTexts.featuredSectionsLabel[langSelector.lang]}</Typography>
        <Grid container spacing={{xs: 0, md: 3}} xs={12}>
            {props.categories?.map(value =>
                <Grid xs={12} md={4}>
                    <Box component={"div"} paddingY={2}>
                        <FeaturedCategoryItemComponent category={value.category} items={value.feature_news}/>
                    </Box>
                </Grid>)}
        </Grid>
    </Stack>
}

export default FeaturedCategoriesComponent