import {Alert, Box, Button, Grid, Input, Stack, Textarea, Typography} from "@mui/joy";
import "./styles.css"

const CookieSettingsPage = () => {
    return <Box component={"div"} paddingX={{xs: "25px", lg: "15%"}} marginTop={10} paddingY={{xs: 4, lg: 10}}
                boxSizing={"border-box"} display={"flex"} flexDirection={"column"} gap={7}>

        <Typography level={"h2"}>
            Politique de Gestion des Cookies
        </Typography>

        <Stack>

            <Box component={"img"} width={"auto"} height={200} src={require("../../assets/images/logo.jpg")}
                 sx={{objectFit: "contain"}}/>

            <div className="container">
                <Typography level={"title-lg"} textAlign={"justify"} lineHeight={2}>
                    Chez Hopna
                    ("nous", "notre" ou "nos"), nous utilisons des cookies et d'autres technologies similaires pour
                    améliorer votre expérience sur notre site web. Cette politique de gestion des cookies explique ce
                    que sont les cookies, comment nous les utilisons, et comment vous pouvez les gérer.

                    <br/> <br/>1. Qu'est-ce qu'un Cookie ?
                    Un cookie est un petit fichier texte qui est placé sur votre appareil (ordinateur, tablette,
                    smartphone) lorsque vous visitez un site web. Les cookies permettent au site web de reconnaître
                    votre appareil et de se souvenir de certaines informations sur votre visite, comme vos préférences,
                    vos actions et vos paramètres.

                    <br/> <br/>2. Types de Cookies Utilisés
                    Nous utilisons plusieurs types de cookies sur notre site web :

                    Cookies Essentiels : Ces cookies sont nécessaires au fonctionnement de notre site web. Ils vous
                    permettent de naviguer sur le site et d'utiliser ses fonctionnalités, comme l'accès aux zones
                    sécurisées.
                    Cookies de Performance : Ces cookies collectent des informations sur la façon dont les visiteurs
                    utilisent notre site, comme les pages les plus visitées et les éventuels messages d'erreur. Ils nous
                    aident à améliorer le fonctionnement du site.
                    Cookies de Fonctionnalité : Ces cookies permettent à notre site de se souvenir de vos choix (comme
                    votre nom d'utilisateur, votre langue ou la région où vous vous trouvez) et de fournir des
                    fonctionnalités améliorées et plus personnalisées.
                    Cookies de Ciblage/Publicité : Ces cookies sont utilisés pour vous proposer des publicités
                    pertinentes en fonction de vos centres d'intérêt. Ils se souviennent des sites web que vous avez
                    visités et ces informations peuvent être partagées avec d'autres parties, telles que des annonceurs.
                    <br/> <br/>3. Gestion des Cookies
                    Vous pouvez contrôler et gérer les cookies de différentes manières :

                    Paramètres du Navigateur : La plupart des navigateurs web vous permettent de gérer les cookies via
                    leurs paramètres. Vous pouvez configurer votre navigateur pour refuser les cookies ou pour supprimer
                    certains cookies. Les instructions pour gérer les cookies dans les navigateurs courants sont
                    disponibles ici :

                    Google Chrome
                    Mozilla Firefox
                    Safari
                    Microsoft Edge
                    Outils de Désactivation : Vous pouvez utiliser des outils en ligne pour désactiver certains cookies
                    tiers, tels que ceux fournis par Your Online Choices ou Network Advertising Initiative.

                    <br/> <br/>4. Consentement et Retrait du Consentement
                    En continuant à utiliser notre site web, vous consentez à l'utilisation des cookies conformément à
                    cette politique. Vous pouvez retirer votre consentement à tout moment en supprimant les cookies de
                    votre navigateur et en ajustant les paramètres de votre navigateur pour refuser les cookies à
                    l'avenir.

                    <br/> <br/>5. Modifications de cette Politique
                    Nous nous réservons le droit de mettre à jour cette politique de gestion des cookies de temps en
                    temps. Toute modification sera publiée sur cette page et prendra effet immédiatement. Nous vous
                    encourageons à consulter régulièrement cette page pour rester informé de notre utilisation des
                    cookies.

                    <br/> <br/>6. Contact
                    Si vous avez des questions ou des préoccupations concernant notre utilisation des cookies, veuillez
                    nous contacter à info@hopna-agency.media

                    <br/> <br/> <b>Dernière mise à jour : 12/07/2024</b>
                </Typography>
            </div>

        </Stack>

    </Box>
}

export default CookieSettingsPage