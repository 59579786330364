import axios, {AxiosRequestConfig} from "axios";

export const get = <D>(url: string, options?: AxiosRequestConfig): Promise<D> =>
    new Promise<D>((resolve, reject) => {
        axios.get(url, options).then(response => {
            const rawData = response.data
            if (!rawData['status']) {
                console.log("Unknown error has occurred")
                return reject()
            }

            resolve(response.data['data'] as D)
        }).catch(err => {
            console.log(err)
            reject()
        })
    })


export const post = <D>(url: string, body: object, options?: AxiosRequestConfig): Promise<D> =>
    new Promise<D>((resolve, reject) => {
        axios.post(url, body, options).then(response => {
            const rawData = response.data
            if (!rawData['status']) {
                console.log("Unknown error has occurred")
                return reject()
            }

            resolve(rawData['data'] as D)
        }).catch(err => {
            console.log(err)
            reject()
        })
    })

export const put = <D>(url: string, body: object, options?: AxiosRequestConfig): Promise<D> =>
    new Promise<D>((resolve, reject) => {
        axios.put(url, body, options).then(response => {
            const rawData = response.data
            if (!rawData['status']) {
                console.log("Unknown error has occurred")
                return reject()
            }

            resolve(rawData['data'] as D)
        }).catch(err => {
            console.log(err)
            reject()
        })
    })