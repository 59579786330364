import Category from "../types/category";
import {HOST} from "../constants";
import axios from "axios";
import {get} from "./index";
import {translateToFr} from "./lang";
import {forEach} from "lodash";

export const getCategories = async (): Promise<Category[]> => {
   return  await get<Category[]>(HOST + "/category", {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}