export const authTexts = {
    authRequired: {
        en: "You will need to login to access this resource",
        fr: "Vous devrez vous connecter pour accéder à cette ressource"
    },
    signInTitle: {
        en: "Sign in to your account",
        fr: "Connectez-vous à votre compte"
    },
    signUpTitle: {
        en: "Sign up for an account",
        fr: "Créer un compte"
    },
    fullNameLabel: {
        en: "Full name",
        fr: "Nom et prénom"
    },
    phoneLabel: {
        en: "Phone",
        fr: "Numero de téléphone"
    },
    dateOfBirthLabel: {
        en: "Date of birth",
        fr: "Date de naissance"
    },
    confirmPasswordLabel: {
        en: "Confirm password",
        fr: "Confirmer le mot de passe"
    },
    genderLabel: {
        en: "Gender",
        fr: "Genre"
    },
    femaleLabel: {
        en: "Female",
        fr: "Féminin"
    },
    maleLabel: {
        en: "Male",
        fr: "Masculin"
    },
    signUpDisclaimerAgreement: (privacyPolicy: string, termsOfUse: string) => {
        return {
            en: `By signing up for an account you agree to the HOPNA ${termsOfUse} et notre ${privacyPolicy}.`,
            fr: `En vous créant un compte, vous certifiez etre en accord avec les ${termsOfUse} et la ${privacyPolicy} de HOPNA.`
        }
    },
    termsOfUseLabel: {
        en: "Terms of use",
        fr: "Conditions d'utilisation"
    },
    privacyPolicyLabel: {
        en: "Privacy policy",
        fr: "Politique de confidentialité"
    },
    signUpLabel: {
        en: "Sign up",
        fr: "S'inscrire"
    },
    signInLabel: {
        en: "Sign in",
        fr: "Se connecter"
    },
    noAccountLabel: {
        en: "Don't have an account ?",
        fr: "Vous n'avez pas de compte ?"
    },
    hasAccountLabel: {
        en: "Already have an account ?",
        fr: "Vous etes deja inscrit ?"
    },
    emailAddressLabel: {
        en: "Email address",
        fr: "Addresse e-mail"
    },
    passwordLabel: {
        en: "Password",
        fr: "Mot de passe"
    },
    passwordForgottenLabel: {
        en: "Password forgotten?",
        fr: "Mot de passe oublié?"
    },
    invalidCredentials: {
        en: "Email or password is invalid, please retry.",
        fr: "L'e-mail ou le mot de passe n'est pas valide, veuillez réessayer."
    },
    resetPasswordTitle: {
        en: "Enter your email to reset your password",
        fr: "Entrez votre email pour réinitialiser votre mot de passe"
    },
    resetPasswordHelperText: {
        en: "We will send a mail only if this email address matches a registered account",
        fr: "Nous n'enverons un mail que si l'addresse e-mail correspond à un compte"
    },
    resetPasswordSuccessText: {
        en: "We sent you an email to reset your password",
        fr: "Nous vous avons envoyé un mail pour réinitialiser votre mot de passe"
    },
    resetPasswordLabel: {
        en: "Reser password",
        fr: "Réinitialiser le mot de passe"
    }

}

export default authTexts