import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {post} from "../../api";
import {HOST} from "../../constants";
import {
    PLAN_ACCESS_MONTHLY,
    PLAN_ACCESS_TERMLY,
    PLAN_ACCESS_YEARLY,
    PLAN_ADVANCED_MONTHLY,
    PLAN_ADVANCED_TERMLY,
    PLAN_ADVANCED_YEARLY,
    PLAN_PREMIUM_MONTHLY,
    PLAN_PREMIUM_TERMLY,
    PLAN_PREMIUM_YEARLY
} from "./constants";
import {Box, Typography} from "@mui/joy";
import {useAppSelector} from "../../hooks";
import subscribeTexts from "./subscribeTexts";

const SUBSCRIPTION_PLANS = [PLAN_ACCESS_MONTHLY, PLAN_ACCESS_TERMLY, PLAN_ACCESS_YEARLY, PLAN_ADVANCED_MONTHLY, PLAN_ADVANCED_TERMLY,
    PLAN_ADVANCED_YEARLY, PLAN_PREMIUM_MONTHLY, PLAN_PREMIUM_TERMLY, PLAN_PREMIUM_YEARLY]
const SubscribePage = () => {
    const navigate = useNavigate()
    const routerLocation = useLocation()
    const userSelector = useAppSelector(state => state.user)
    const languageSelector = useAppSelector(state => state.lang)
    const [redirectErr, setRedirectErr] = useState(false)

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()
    const queryAuth = query.get("auth") === null ?? query.get("auth")

    console.log(`auth defined: ${!!query.get("auth")}`)

    useEffect(() => {
        if (!userSelector.user && !query.get("auth"))
            return navigate("/account/login?reason=auth-required&&next=" + routerLocation.pathname + routerLocation.search)

        if (query.get("plan")) {
            post<any>(HOST + "/make-payment", {
                subscription_id: SUBSCRIPTION_PLANS.find(value => value.label === query.get("plan")).subscriptionId
            }, {
                headers: {
                    Authorization: "Bearer " + (query.get("auth") || userSelector.user?.token)
                }
            }).then(value => {
                window.location.href = value.url as string
            }).catch(err => {
                setRedirectErr(true)
                setTimeout(() => {
                    navigate("/")
                }, 3000)
                console.log(`${err}`)
            })
        }

    }, [])

    return <Box component={"div"} padding={2}>
        <Typography level={"title-lg"}>
            {subscribeTexts.redirectionText[languageSelector.lang]}
        </Typography>
        {redirectErr && <Typography level={"title-lg"} color={"danger"}>
            {subscribeTexts.redirectionErrorText[languageSelector.lang]}
        </Typography>}
    </Box>
}

export default SubscribePage