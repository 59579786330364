import {Box, Grid, Stack, Typography} from "@mui/joy";
import {VideoArticleComponent2, LiveTvVideoComponent} from "../ArticleComponent";
import {useEffect, useRef, useState} from "react";
import "./styles.css"
import {useLocation} from "react-router-dom";
import {topArticles} from "../TopArticles/dummies";
import {CloseOutlined, LiveTvRounded, TvRounded} from "@mui/icons-material";
import {get} from "../../api";
import {HOST} from "../../constants";
import {Program} from "../../types/program";
import {useAppSelector} from "../../hooks";

const LiveTVComponent = ({position}) => {
    const location = useLocation()
    const userSelector = useAppSelector(state => state.user)
    const [currentProgram, setCurrentProgram] = useState<Program>()
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [programs, setPrograms] = useState<Program[]>([])
    const blacklistRoutes = ["/bantou-club-debates", "/live-meeting", "/terms-of-use", "/privacy-policy", "/cookie-settings", "/categories", "/my-account", `/articles`, "/contact-us", "/subscription", "/live-events", "/about-us", "/my-account"]

    useEffect(() => {
        get<Program>(HOST + "/current-program", {})
            .then(value => {
                setCurrentProgram(value)
            })


        get<Program[]>(HOST + "/programs", {})
            .then(value => {
                setPrograms(value)
            })
            .catch(err => {
                console.log(`${err}`)
            })
    }, [])

    if (blacklistRoutes.includes(location.pathname.substring(location.pathname.indexOf("/"))) ||
        blacklistRoutes.includes(location.pathname.substring(location.pathname.indexOf("/"), location.pathname.lastIndexOf("/"))))
        return null

    return <>
        <Box component={'div'}
             onClick={() => {
                 if (!isFullScreen)
                     setIsFullScreen(true)
             }}
             sx={{position: isFullScreen ? "fixed" : position}}
             paddingX={isFullScreen ? {xs: 3, lg: "15%"} : 0}
             paddingY={isFullScreen ? 2 : 0}
             width={isFullScreen ? "100%" : {xs: 200, md: 300}}
             height={isFullScreen ? "100%" : {xs: 150, md: 200}}
             className={isFullScreen ? "live-tv-expanded-container" : "live-tv-collapsed-container"}>

            {!isFullScreen && ((!currentProgram?.link || currentProgram?.url?.[0]) && <Box component={"video"}
                                                                                           src={currentProgram?.url?.[0] || "https://videos.pexels.com/video-files/7924460/7924460-sd_540_960_24fps.mp4"}
                                                                                           className={"live-tv-video-stream"}
                                                                                           height={{xs: 150, md: 200}}
                                                                                           display={isFullScreen ? "none" : "block"}
                                                                                           muted
                                                                                           autoPlay/> ||
                (currentProgram?.link && <Box component={"iframe"}
                                              src={"https://youtube.com/embed/" + currentProgram?.link.substring(currentProgram.link.lastIndexOf("/") + 1) + "?autoplay=1"}
                                              height={{xs: 150, md: 200}} allow={"autoplay"} allowFullScreen
                                              display={isFullScreen ? "none" : "block"}
                />))}

            {isFullScreen &&
                <Stack gap={3} position={"fixed"} sx={{backgroundColor: "#303030", zIndex: 1, top: 0, left: 0}}
                       alignItems={"start"} paddingY={2} width={"100%"} justifyContent={"space-between"}
                       direction={"row"}>

                    <Stack marginLeft={4}>
                        <Stack direction={"row"} alignItems={"center"} marginY={1} gap={1.5}>
                            <Box component={"img"} borderRadius={10} width={50} height={50}
                                 src={require("../../assets/images/logo.jpg")}/>
                            <Typography level={"h3"} textAlign={"start"} sx={{color: "white"}}>Hopna TV</Typography>
                        </Stack>

                        <Typography level={"title-md"} textAlign={"start"} sx={{color: "lightgrey"}}>
                            Discover exclusive shows, interviews and much more
                        </Typography>
                    </Stack>

                    <Box component={"div"}
                         onClick={e => {
                             e.preventDefault()
                             setIsFullScreen(false)
                         }}
                         sx={{width: "max-content", marginY: 2, marginRight: 4, cursor: "pointer"}}>
                        <CloseOutlined
                            sx={{fontSize: "x-large"}}/>
                    </Box>

                </Stack>}

            {isFullScreen &&
                <Grid container spacing={4} marginTop={11}>
                    <Grid xs={12} md={8}>
                        <Stack gap={3} marginBottom={3}>
                            <Typography level={"title-lg"} textAlign={"start"} sx={{color: "white"}}>
                                Currently playing
                            </Typography>

                            {(currentProgram?.url?.[0]) && <Box component={"video"}
                                                                src={currentProgram?.url?.[0] || "https://videos.pexels.com/video-files/7924460/7924460-sd_540_960_24fps.mp4"}
                                                                className={"live-tv-video-stream"}
                                                                height={{xs: 300, md: "30rem"}}
                                                                display={isFullScreen ? "block" : "none"}
                                                                muted controls
                                                                autoPlay/>}

                            {(!currentProgram?.url?.[0]) && currentProgram?.link &&
                                <Box component={"iframe"}
                                     src={"https://youtube.com/embed/" + currentProgram.link.substring(currentProgram.link.lastIndexOf("/") + 1)}
                                     height={{xs: 300, md: "30rem"}}
                                     display={isFullScreen ? "block" : "none"}
                                />}
                            <Typography textAlign={"start"} level={"h3"} sx={{color: "white"}}>
                                {currentProgram?.title}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid xs={12} md={4}>
                        <Stack gap={4}>
                            <Typography textAlign={"start"} level={"title-lg"} sx={{color: "white"}}>
                                More shows
                            </Typography>

                            <Stack gap={3.5} style={{overflowY: "auto"}}
                                   sx={{
                                       scrollbarColor: "grey transparent",
                                       scrollbarWidth: "thin",
                                       scrollbarGutter: "unset"
                                   }}
                                   height={"70vh"} paddingBottom={5}>
                                {programs.map((value, index) =>
                                    index > 0 &&
                                    <LiveTvVideoComponent onClick={() => setCurrentProgram(value)} program={value}/>)}
                            </Stack>

                        </Stack>
                    </Grid>

                </Grid>
            }

            {!isFullScreen &&
                <Box component={"div"} sx={{backgroundColor: "primary.700"}}
                     position={"absolute"} top={0} paddingX={1.5}>
                    <Typography sx={{color: "white"}} level={"body-sm"} fontWeight={"bold"}>
                        HOPNA TV
                    </Typography>
                </Box>}


        </Box>
    </>
}

export default LiveTVComponent