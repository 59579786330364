import {Box, Card, CardContent, Stack, Typography} from "@mui/joy";
import Article from "../../types/article";
import "./styles.css"
import {useEffect, useRef, useState} from "react";
import {FullscreenOutlined, PlayCircle, PlayCircleOutline} from "@mui/icons-material";
import {useAppSelector} from "../../hooks";
import {Program} from "../../types/program";

type ArticleComponentProps = {
    article?: Article,
    onClick?: (article: Article) => void,
}

export const ArticleComponent1 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <Card orientation={"horizontal"} size={"lg"} variant={"outlined"} sx={{padding: 0, height: "100%"}}>
        <Box component={"div"} className={"article1-img-container"}>
            <img src={props.article.images[0][0]} className={"article-img"}/>
        </Box>

        <CardContent orientation={"vertical"} sx={{paddingX: 2, paddingY: 1}}>
            <Stack flexDirection={"row"} gap={0.5} alignItems={"center"}>
                <Box component={"div"} className={"article1-category-container"}>
                    {props.article?.categories?.[0].name}
                </Box>

                <Typography color={"primary"}
                            level={"body-xs"}>{new Date(props.article?.published_at).toDateString()}</Typography>
            </Stack>

            <Typography level={"title-lg"} textAlign={"start"}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>

            <Typography level={"body-xs"} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                <div style={{
                    textAlign: "start",
                }} dangerouslySetInnerHTML={{
                    __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                }}/>
            </Typography>

        </CardContent>

    </Card>
}
export const ArticleComponent2 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <Card orientation={"horizontal"} size={"sm"} sx={{padding: 0}}>
        <Box component={"div"} className={"article2-img-container"}>
            <img src={props.article.images[0][0]} className={"article-img-small"}/>
        </Box>

        <CardContent orientation={"vertical"} sx={{padding: 2}}>
            <Stack flexDirection={"row"} gap={1.5} alignItems={"center"}>
                <Box component={"div"} className={"article1-category-container"}>
                    {props.article?.categories?.[0].name}
                </Box>

                <Typography color={"primary"}
                            level={"body-xs"}>{new Date(props.article?.published_at).toDateString()}</Typography>
            </Stack>

            <Typography level={"title-md"} textAlign={"start"}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>

            <Typography level={"body-xs"} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                <div style={{
                    textAlign: "start",
                }} dangerouslySetInnerHTML={{
                    __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                }}/>
            </Typography>

        </CardContent>

    </Card>
}

export const ArticleComponent4 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <Stack gap={0.5}>
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
            <Box component={"div"} className={"article1-category-container"}>
                {props.article?.categories?.[0].name}
            </Box>
        </Stack>
        <a href={"/articles/" + props.article.id} className={"text-normal"}>

            <Typography level={"title-sm"} textAlign={"start"}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>

            <Typography level={"body-xs"} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                <div style={{
                    textAlign: "start",
                }} dangerouslySetInnerHTML={{
                    __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                }}/>
            </Typography>
        </a>

    </Stack>

}


export const ArticleComponent5 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"

    return <a href={"/articles/" + props.article?.id}>
        <Stack gap={1.5} direction={{xs: "column", md: "row"}}>
            <Box component={"img"} width={{xs: "100%", md: '15rem'}} src={props.article.images?.[0]?.[0]}
                 className={"article-img"}/>
            <Typography level={"title-sm"} textAlign={"start"}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>
        </Stack>
    </a>

}

export const ArticleComponent7 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <a href={"/articles/" + props.article?.id}>
        <Stack gap={1.5}>
            <Box component={"img"} width={"100%"} src={props.article.images?.[0]?.[0]}
                 className={"article-img"}/>

            <Typography level={"h4"} fontWeight={700} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>

            <Typography level={"body-md"} textAlign={"start"}>
                <div style={{
                    textAlign: "start",
                }} dangerouslySetInnerHTML={{
                    __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                }}/>
            </Typography>
        </Stack>
    </a>

}

export const ArticleComponent6 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <a href={"/articles/" + props.article?.id}>
        <Stack gap={0.5}>
            <Typography level={"h4"} fontWeight={700} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                {props.article?.[articleTitleField] || props.article?.title}
            </Typography>
            <Typography level={"body-sm"} textAlign={"start"}>
                <div style={{
                    textAlign: "start",
                }} dangerouslySetInnerHTML={{
                    __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                }}/>
            </Typography>
        </Stack>
    </a>

}

export const VideoArticleComponent1 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <a href={"/videos"}>
        <Stack gap={2}>
            <Box component={"div"} className={"video-article-container"}>
                <video className={"video-article1-video"}
                       src={"https://videos.pexels.com/video-files/5544520/5544520-hd_1920_1080_24fps.mp4"}>
                </video>

                <Box component={"div"} display={"flex"} padding={0.5} left={0} bottom={0}
                     sx={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}} alignItems={"center"} gap={0.5}
                     position={"absolute"} marginBottom={0.5}>
                    <PlayCircleOutline/>
                    <Typography level={"body-xs"} fontWeight={"bold"}
                                sx={{color: "inherit"}}>VIDEO</Typography>
                </Box>
            </Box>
            <Typography level={"body-md"} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                <Typography level={"body-md"} textAlign={"start"}>
                    <div style={{
                        textAlign: "start",
                    }} dangerouslySetInnerHTML={{
                        __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                    }}/>
                </Typography>
            </Typography>
        </Stack>
    </a>
}

export const VideoArticleComponent2 = (props: ArticleComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"

    return <a href={"/videos"}>
        <Stack gap={2} direction={{xs: "column", md: "row"}}>
            <Box component={"div"} className={"video-article-container"}>
                <Box component={"video"} height={{xs: 220, md: 150}} width={{xs: "100%", md: 220}}
                     sx={{objectFit: "cover", position: "relative"}}
                     src={"https://videos.pexels.com/video-files/20325563/20325563-uhd_3840_2160_60fps.mp4"}/>

                <Box component={"div"} display={"flex"} padding={0.5} left={0} bottom={0}
                     sx={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}} alignItems={"center"} gap={0.5}
                     position={"absolute"} marginBottom={0.5}>
                    <PlayCircleOutline/>
                    <Typography level={"body-xs"} fontWeight={"bold"}
                                sx={{color: "inherit"}}>VIDEO</Typography>
                </Box>
            </Box>
            <Typography level={"body-sm"} textAlign={"start"} sx={{textOverflow: "ellipsis"}}>
                <Typography level={"body-md"} textAlign={"start"}>
                    <div style={{
                        textAlign: "start",
                    }} dangerouslySetInnerHTML={{
                        __html: `<div>${(props.article?.[articleContentField] || props.article?.content)?.substring(0, 200) + "..."}</div>`
                    }}/>
                </Typography>
            </Typography>
        </Stack>
    </a>
}

export const LiveTvVideoComponent = (props: { program: Program, onClick: () => void }) => {
    const videoIdBeginIndex = props.program.link?.lastIndexOf("/")
    const videoId = props.program.link?.substring(videoIdBeginIndex + 1)
    return <Stack gap={2} onClick={props.onClick}>
        <Box component={"div"} className={"video-article-container"}>

            {(props.program.url?.[0]) &&
                <video className={"video-article1-video"}
                       src={props.program.url?.[0]}>
                </video>}

            {(!props.program.url?.[0] && props.program.link) &&
                <iframe
                    className={"video-article1-video"}
                    src={"https://youtube.com/embed/" + videoId}/>}

            <Box component={"div"} display={"flex"} padding={0.5} left={0} top={0}
                 sx={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}} alignItems={"center"} gap={0.5}
                 position={"absolute"} marginBottom={0.5}>
                {props.program?.url?.[0] ? <PlayCircleOutline/> : props.program.link &&
                    <FullscreenOutlined sx={{cursor: "pointer"}} onClick={props.onClick}/>}
                {props.program?.url?.[0] && <Typography level={"body-xs"} fontWeight={"bold"}
                                                    sx={{color: "inherit"}}>VIDEO</Typography>}
            </Box>
        </Box>
        <Typography level={"body-md"} textAlign={"start"} sx={{textOverflow: "ellipsis", color: "white"}}>
            {props.program.title}
        </Typography>
    </Stack>
}


