import {SxProps} from "@mui/joy/styles/types";

type SxStyleReturnType = {
    [key: string]: SxProps,
}

const styles: SxStyleReturnType = {
    categoryBarContainer: {
        flexDirection: "row",
        gap: 2.5,
        alignItems: 'center',
        paddingY: 1,
        paddingX: {xs: "25px", lg: "10%"},
        backgroundColor: "white",
        color: "#A33E02"
    },
}

export default styles