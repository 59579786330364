export const PLAN_ACCESS_MONTHLY = {
    label: "access-monthly",
    subscriptionId: 1
}
export const PLAN_ACCESS_TERMLY = {
    label: "access-termly",
    subscriptionId: 4
}
export const PLAN_ACCESS_YEARLY = {
    label: "access-yearly",
    subscriptionId: 5
}
export const PLAN_ADVANCED_MONTHLY = {
    label: "advanced-monthly",
    subscriptionId: 2
}
export const PLAN_ADVANCED_TERMLY = {
    label: "advanced-termly",
    subscriptionId: 6
}
export const PLAN_ADVANCED_YEARLY = {
    label: "advanced-yearly",
    subscriptionId: 7
}
export const PLAN_PREMIUM_MONTHLY = {
    label: "premium-monthly",
    subscriptionId: 3
}
export const PLAN_PREMIUM_TERMLY = {
    label: "premium-termly",
    subscriptionId: 8
}
export const PLAN_PREMIUM_YEARLY = {
    label: "premium-yearly",
    subscriptionId: 9
}

