import {
    Box,
    Drawer, Dropdown, List, ListItem,
    Stack,
    Typography
} from "@mui/joy";
import "./styles.css"
import Category from "../../types/category";
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {fetchCategories} from "../../store/categorySlice";
import {ArrowDownwardOutlined, ArrowDropDownOutlined} from "@mui/icons-material";
import styles from "../Appbar/styles";
import {navbarTexts} from "../../texts";
import {useEffect, useState} from "react";
import {translateToFr} from "../../api/lang";
import appbarTexts from "../Appbar/navbarTexts";

type NavbarMenuDrawerProps = {
    visible?: boolean,
    appbarHeight?: number,
    onMenuClose?: () => void,
}

const NavbarMenuDrawer = (props: NavbarMenuDrawerProps) => {
    const location = useLocation()
    const currentRoute = location.pathname
    const categoriesSelector = useAppSelector(state => state.categories)
    const langSelector = useAppSelector(state => state.lang)
    const categoryNameField = langSelector.lang === "fr" ? "name_fr" : "name"


    return <Drawer open={props.visible} anchor={"top"} sx={{
        zIndex: 1,
    }} onClose={props.onMenuClose} size={"sm"}>
        <Box component={"div"} className={"drawer-container"} display={"flex"} flexDirection={"column"}>
            <Stack direction={"row"} gap={2} paddingX={1.5} paddingY={2} sx={{backgroundColor: "primary.500"}}
                   justifyContent={"space-between"} alignItems={"center"}>
                {navbarTexts.map(value =>
                    <a href={value.url}>
                        <Typography level={"body-xs"} textColor={"white"} textAlign={"center"}>
                            {appbarTexts[value.title][langSelector.lang]}
                        </Typography>
                    </a>)}
            </Stack>


            <Stack flexDirection={"row"} gap={5} flexWrap={"wrap"} padding={3} justifyContent={"start"}>
                {categoriesSelector.categories?.map(value => <a
                    className={"text-normal"} href={`/categories/${value.id}`}>
                    <Typography textAlign={"start"} sx={{
                        color: currentRoute === "/" + value.name ? "#A33E02" : "initial",
                        fontWeight: currentRoute === "/" + value.name ? "bold" : "initial",
                    }} level={"body-md"}>
                        {value[categoryNameField] || value.name}
                    </Typography>
                </a>)}
            </Stack>
        </Box>
    </Drawer>

}

export default NavbarMenuDrawer