const appbarTexts = {
    profileLabel: {
        en: "Profile",
        fr: "Mon profile"
    },
    logoutLabel: {
        en: "Logout",
        fr: "Deconnexion"
    },
    homeLabel: {
        en: "Home",
        fr: "Accueil"
    },
    subscriptionLabel: {
        en: "Subscription",
        fr: "Souscription"
    },
    liveEventLabel: {
        en: "Live Event",
        fr: "Live Event"
    },
    bantouClubLabel: {
        en: "Bantou Club",
        fr: "Club Bantu"
    },
    contactUsLabel: {
        en: "Contact us",
        fr: "Contactez-nous"
    }
}

export default appbarTexts