import TrendingNews from "../../../components/TrendingNews";
import LatestNewsComponent from "../../../components/LatestNews";
import "../../page-styles.css"
import PopularNewsComponent from "../../../components/PopularNews";
import {Box, Grid} from "@mui/joy";
import FeaturedStoriesComponent from "../../../components/FeaturedStories";
import FeaturedCategoriesComponent from "../../../components/FeaturedSections";
import VideosSectionComponent from "../../../components/VideosSectionComponent";
import Appbar from "../../../components/Appbar";
import BantouClubComponent from "../../../components/TopArticles";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {loadIndex} from "../../../store/indexSlice";
import {useEffect} from "react";
import LoadingComponent from "../../../components/LoadingComponent";
import HorizontalRectangleAdvertisementComponent from "../../../components/Advertisement/HorizontalRectangleAdvertisementComponent";
import VerticalRectangleAdvertisementComponent
    from "../../../components/Advertisement/VerticalRectangleAdvertisementComponent";

const Home = () => {
    const articleSlice = useAppSelector(state => state.index)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(loadIndex())
    }, [])

    return <Box component={"div"} className={"home-container"}>
        {articleSlice.isLoading ? <LoadingComponent/> :
            <>
                <TrendingNews articles={articleSlice.topStories}/>
                <Box component={"div"} className={"main-page-outlet-container"} paddingX={{xs: "25px", lg: "10%"}}
                     boxSizing={"border-box"}>
                    <Box component={"div"} gap={3} className={"home-horizontal-container"}>
                        <Grid container spacing={3}>
                            <Grid xs={12} paddingY={3}>
                                <HorizontalRectangleAdvertisementComponent/>
                            </Grid>

                            <Grid xs={12} md={4} lg={3}>
                                <LatestNewsComponent articles={articleSlice.breakingNews}/>
                            </Grid>


                            <Grid xs={12} md={8} lg={6}>
                                <FeaturedStoriesComponent articles={articleSlice.featuredNews}/>
                            </Grid>

                            <Grid xs={12} lg={3} rowGap={5} >
                                <BantouClubComponent/>
                                <VerticalRectangleAdvertisementComponent/>
                            </Grid>
                        </Grid>

                    </Box>
                    <FeaturedCategoriesComponent categories={articleSlice.featuredCategories}/>
                </Box>
            </>}
    </Box>
}

export default Home