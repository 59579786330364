import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./pages/Error";
import {CssVarsProvider} from "@mui/joy/styles";
import theme from "./theme";
import Main from "./pages/Main";
import Home from "./pages/Main/Home";
import AuthPage from "./pages/Account";
import LoginPage from "./pages/Account/Login";
import RegisterPage from "./pages/Account/Register";
import VideosPage from "./pages/Main/Videos";
import ArticlePage from "./pages/Article";
import {Provider} from "react-redux";
import store from "./store";
import BantouClubPage from "./pages/Main/BantouClub";
import CategoryPage from "./pages/Category";
import ContactUsPage from "./pages/ContactUs";
import AboutUsPage from "./pages/AboutUs";
import SubscribePage from "./pages/Subscribe";
import MyAccountPage from "./pages/Main/MyAccount";
import SubscriptionPage from "./pages/Main/Subscription";
import LiveEventPage from "./pages/Main/LiveEvent";
import ResetPasswordPage from "./pages/Account/ResetPassword";
import LiveEventMeetingPage from "./pages/Main/LiveEvent/LiveEventMeetingPage";
import TermsOfUsePage from "./pages/TermsOfUse";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import CookieSettingsPage from "./pages/CookieSettings";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: '',
                element: <Main/>,
                children: [
                    {
                        path: '',
                        element: <Home/>
                    },
                    {
                        path: '/categories/:categoryId',
                        element: <CategoryPage/>
                    },
                    {
                        path: '/contact-us',
                        element: <ContactUsPage/>
                    },
                    {
                        path: '/about-us',
                        element: <AboutUsPage/>
                    }, {
                        path: '/terms-of-use',
                        element: <TermsOfUsePage/>
                    }, {
                        path: '/privacy-policy',
                        element: <PrivacyPolicyPage/>
                    }, {
                        path: '/cookie-settings',
                        element: <CookieSettingsPage/>
                    },
                    {
                        path: "/articles/:articleId",
                        element: <ArticlePage/>,
                    },
                    {
                        path: "/live-events",
                        element: <LiveEventPage/>,
                    },
                    {
                        path: "/live-meeting",
                        element: <LiveEventMeetingPage/>,
                    },
                    {
                        path: "/subscription",
                        element: <SubscriptionPage/>,
                    },
                    {
                        path: "/bantou-club-debates",
                        element: <BantouClubPage/>,
                    },
                    {
                        path: "/my-account",
                        element: <MyAccountPage/>,
                    },
                ]
            },
            {
                path: "/account",
                element: <AuthPage/>,
                children: [{
                    path: "/account/login",
                    element: <LoginPage/>,
                }, {
                    path: "/account/register",
                    element: <RegisterPage/>,
                }, {
                    path: "/account/reset-password",
                    element: <ResetPasswordPage/>,
                },]
            },
            {
                path: "/subscribe",
                element: <SubscribePage/>,
            }
        ]
    },
]);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CssVarsProvider theme={theme}>
                <RouterProvider router={router}/>
            </CssVarsProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
