export const sectionTexts = [{
    title: "Politics",
    url: "/politics",
}, {
    title: "Science and Tech",
    url: "/science-and-tech",
}, {
    title: "Discovery",
    url: "/discovery",
}, {
    title: "Business",
    url: "/business",
}, {
    title: "Health",
    url: "/health",
}, {
    title: "Entertainment",
    url: "/politics",
}, {
    title: "Arts & Culture",
    url: "/arts-and-culture",
}, {
    title: "Sports",
    url: "/sports",
}, {
    title: "Videos",
    url: "/videos",
}, {
    title: "Weather",
    url: "/weather",
},]

export const navbarTexts = [{
    title: "homeLabel",
    url: "/"
}, {
    title: "subscriptionLabel",
    url: "/subscription"
}, {
    title: "liveEventLabel",
    url: "/live-events"
}, {
    title: "bantouClubLabel",
    url: "/bantou-club-debates"
}, {
    title: "contactUsLabel",
    url: "/contact-us"
}]