import {
    Avatar,
    Box,
    Button,
    Dropdown,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Typography
} from "@mui/joy";
import "../styles.css"
import {CloseOutlined, LiveTvRounded, MenuOutlined, PersonOutlined, PersonRounded} from "@mui/icons-material";
import {navbarTexts} from "../../texts";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {fetchCategories} from "../../store/categorySlice";
import {useState} from "react";
import {resetUser} from "../../store/userSlice";
import {setLang} from "../../store/languageSlice";
import appbarTexts from "./navbarTexts";
import authTexts from "../../pages/Account/authTexts";

type NavbarProps = {
    onNavbarToggle?: () => void,
    navbarOpen?: boolean
}

const NavBar = (props: NavbarProps) => {
    const userSelector = useAppSelector(state => state.user)
    const langSelector = useAppSelector(state => state.lang)
    const dispatch = useAppDispatch()
    const frLangFlag = "https://flagsapi.com/FR/flat/64.png"
    const enLangFlag = "https://flagsapi.com/GB/flat/64.png"

    return <Box component={"div"} paddingX={{xs: "25px", lg: "10%"}} className={"navbar"}>

        <Stack direction={"row"} gap={2.5} alignItems={"center"}>
            <Box component={"div"} display={{sm: "none"}}>
                {props.navbarOpen ? <CloseOutlined onClick={() => props.onNavbarToggle()} sx={{cursor: "pointer"}}/> :
                    <MenuOutlined onClick={() => props.onNavbarToggle()} sx={{cursor: "pointer"}}/>}
            </Box>

            <a href={"/"}>
                <img src={require("../../assets/images/logo.jpg")} style={{height: 50, width: 50, borderRadius: 15}}/>
            </a>

            <Box component={"div"} display={{xs: "none", sm: "block"}}>
                <Stack direction={"row"} gap={4}>
                    {navbarTexts.map((value, index) =>
                        <a href={value.url} key={index} style={{color: "white"}}>
                            <Typography textColor={"white"}>
                                {appbarTexts[value.title][langSelector.lang]}
                            </Typography>
                        </a>)}
                </Stack>
            </Box>
        </Stack>


        <Stack alignItems={"center"} flexDirection={"row"} gap={2}>
            {userSelector.user ?
                <Dropdown>
                    <MenuButton variant={"outlined"} color={"primary"}
                                sx={{
                                    padding: 0,
                                    width: "max-content",
                                    borderRadius: "100%"
                                }}>
                        <Avatar sx={{color: "white", cursor: "pointer"}} variant={"outlined"}>
                            {userSelector.user.first_name?.split(" ").map((value, index) => {
                                if (index < 2) return value.substring(0, 1)
                            }).join("")}
                        </Avatar>

                    </MenuButton>
                    <Menu size={"lg"}>
                        <a href={"/my-account"}>
                            <MenuItem>{appbarTexts.profileLabel[langSelector.lang]}</MenuItem>
                        </a>
                        <MenuItem onClick={() => dispatch(resetUser())}>{appbarTexts.logoutLabel[langSelector.lang]}</MenuItem>
                    </Menu>
                </Dropdown>
                : <a href={"/account/login"}>
                    <Button variant={"outlined"} sx={{color: "white", borderColor: "white"}}>
                        {authTexts.signInLabel[langSelector.lang]}
                    </Button>
                </a>

            }
            <Dropdown>
                <MenuButton variant={"outlined"} color={"primary"}
                            sx={{
                                padding: 0,
                                width: "max-content",
                                borderRadius: "100%"
                            }}>
                    <Box component={"img"} borderRadius={"100%"} width={32} height={32}
                         sx={{color: "white", objectFit: "cover", cursor: "pointer"}}
                         src={langSelector.lang === "en" ? enLangFlag : frLangFlag}/>
                </MenuButton>
                <Menu size={"lg"}>
                    <MenuItem onClick={() => dispatch(setLang("en"))}>En</MenuItem>
                    <MenuItem onClick={() => dispatch(setLang("fr"))}>Fr</MenuItem>
                </Menu>
            </Dropdown>
        </Stack>
    </Box>
}

export default NavBar
