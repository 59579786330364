import {Box, Button, Divider, Link, Stack, Typography} from "@mui/joy";
import {sectionTexts} from "../../texts";
import {FacebookOutlined, Instagram, InstallDesktopOutlined, LinkedIn, Twitter, X, YouTube} from "@mui/icons-material";
import "./styles.css"
import {useAppSelector} from "../../hooks";
import {useEffect, useRef, useState} from "react";
import {translateToFr} from "../../api/lang";
import appbarTexts from "../Appbar/navbarTexts";
import footerTexts from "./footerTexts";
import authTexts from "../../pages/Account/authTexts";
import TiktokIcon from "../TiktokIcon";


type FooterComponentProps = {
    onVisibilityChange?: (visible: boolean) => void
}

const FooterComponent = (props: FooterComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const categoriesSelector = useAppSelector(state => state.categories)
    const elementRef = useRef<HTMLDivElement>()
    const categoryNameField = langSelector.lang === "fr" ? "name_fr" : "name"

    useEffect(() => {
        if (elementRef.current)
            window.addEventListener('scroll', function () {
                if (!elementRef.current)
                    return

                const position = elementRef.current.getBoundingClientRect()

                if (position.top < window.innerHeight && position.bottom >= 0)
                    props.onVisibilityChange(true)
                else props.onVisibilityChange(false)
            })
    }, [elementRef])

    return <Stack component={"div"} height={"100%"} ref={elementRef}
                  sx={{backgroundColor: 'primary.700'}}
                  paddingX={{xs: "35px", lg: "15%"}} paddingY={"25px"} justifyContent={"space-between"}
                  width={"100%"} boxSizing={"border-box"}>

        <Stack flexDirection={"row"} gap={7} flexWrap={"wrap"} paddingY={4} justifyContent={"center"}>
            {(categoriesSelector.categories)?.map(value =>
                <a style={{color: "white"}}
                   href={`/categories/${value.id}`}>
                    <Typography level={"title-lg"} sx={{
                        color: "white"
                    }} textAlign={"start"}>
                        {value[categoryNameField] || value.name}
                    </Typography>
                </a>
            )}
        </Stack>

        <Divider sx={{height: "1px", backgroundColor: "white"}}/>

        <Stack direction={{xs: "column", md: "row"}} component={"div"} display={"flex"} justifyContent={"space-between"}
               paddingY={3} gap={2}>

            <Stack gap={2.5} direction={{xs: "column", md: "row"}} alignItems={{xs: "start", md: "center"}}>
                <img src={require("../../assets/images/logo.jpg")} style={{height: 90, width: 90, borderRadius: 15}}/>
                <Stack gap={1.5}>
                    <Typography level={"title-lg"} sx={{color: "white"}} textAlign={"start"}>
                        Hotep Pan-African News Agency
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        Avenue des Grandes Communes 8,1213 Petit-Lancy, Genève, Suisse.
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        +41 763034834
                    </Typography>

                    <Divider sx={{height: "1px", backgroundColor: "white"}}/>

                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        Rue Tokoto - Bonapriso, Douala, Littoral, Cameroun.
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        +237 696-369-283
                    </Typography>

                    <a href={"/contact-us"} className={"text-normal"}
                       style={{textAlign: "start", width: "max-content"}}>
                        <Button variant={"outlined"} sx={{borderColor: "white", color: "white"}}>
                            {appbarTexts.contactUsLabel[langSelector.lang]}
                        </Button>
                    </a>
                </Stack>


            </Stack>

            <FollowUsComponent/>

        </Stack>

        <Divider sx={{height: "1px", backgroundColor: "white"}}/>

        <Stack flexDirection={"row"} alignItems={"center"} paddingY={2} justifyContent={"center"} gap={2}>
            <a href={"/about-us"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {footerTexts.aboutUs[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/terms-of-use"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {authTexts.termsOfUseLabel[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/privacy-policy"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {authTexts.privacyPolicyLabel[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/cookie-settings"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {footerTexts.cookieSettings[langSelector.lang]}
                </Typography>
            </a>
        </Stack>

        <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"center"}>
            © 2024 Hotep Pan-African News Agency. All rights reserved.
        </Typography>

    </Stack>
}


const FollowUsComponent = () => {
    const langSelector = useAppSelector(state => state.lang)
    return <Stack alignItems={"center"} gap={2} flexDirection={"row"}>
        <Typography level={"title-md"} sx={{color: "white"}} textAlign={"start"} textTransform={"uppercase"}>
            {footerTexts.followUsLabel[langSelector.lang]}
        </Typography>

        <Link href={"https://www.instagram.com/hopna_agency?igsh=MTM4cGJtcDl4a3J2eg%3D%3D&utm_source=qr"}>
            <Instagram/>
        </Link>

        <Link href={"https://www.facebook.com/profile.php?id=61559122081838"}>
            <FacebookOutlined/>
        </Link>

        <Link href={"https://x.com/HopnaAgency"}>
            <X/>
        </Link>

        <Link
            href={"https://www.linkedin.com/in/hopna-agency-448b751a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"}>
            <LinkedIn/>
        </Link>

        <Link href={"https://youtube.com/@pharaohtv9971?si=P9peS0ZY7E98l6gK"}>
            <YouTube/>
        </Link>

        <Link href={"https://www.tiktok.com/@pharaohtravels?_t=8m2ZL5dHrwu&_r=1"}>
            <TiktokIcon style={{fill: "white"}}/>
        </Link>
    </Stack>

}

export const FooterAltComponent = () => {
    const langSelector = useAppSelector(state => state.lang)
    return <Stack component={"div"} height={"100%"}
                  sx={{backgroundColor: 'primary.700'}}
                  paddingX={{xs: "35px", lg: "15%"}} paddingY={"25px"} justifyContent={"space-between"}
                  width={"100%"} boxSizing={"border-box"}>

        <Stack direction={{xs: "column", md: "row"}} component={"div"} display={"flex"} justifyContent={"space-between"}
               paddingY={3} gap={2}>

            <Stack gap={2.5} direction={{xs: "column", md: "row"}} alignItems={{xs: "start", md: "center"}}>
                <img src={require("../../assets/images/logo.jpg")} style={{height: 90, width: 90, borderRadius: 15}}/>
                <Stack gap={1.5}>
                    <Typography level={"title-lg"} sx={{color: "white"}} textAlign={"start"}>
                        Hotep Pan-African News Agency
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        Avenue des Grandes Communes 8,1213 Petit-Lancy, Genève, Suisse.
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        +41 763034834
                    </Typography>

                    <Divider sx={{height: "1px", backgroundColor: "white"}}/>

                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        Rue Tokoto - Bonapriso, Douala, Littoral, Cameroun.
                    </Typography>
                    <Typography sx={{color: "white"}} textAlign={"start"}>
                        +237 696-369-283
                    </Typography>

                    <a href={"/contact-us"} className={"text-normal"} style={{textAlign: "start"}}>
                        <Button variant={"outlined"} sx={{borderColor: "white", color: "white"}}>
                            {appbarTexts.contactUsLabel[langSelector.lang]}
                        </Button>
                    </a>
                </Stack>


            </Stack>

            <FollowUsComponent/>

        </Stack>

        <Divider sx={{height: "1px", backgroundColor: "white"}}/>

        <Stack flexDirection={"row"} alignItems={"center"} paddingY={2} justifyContent={"center"} gap={2}>
            <a href={"/about-us"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {footerTexts.aboutUs[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/terms-of-use"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {authTexts.termsOfUseLabel[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/privacy-policy"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {authTexts.privacyPolicyLabel[langSelector.lang]}
                </Typography>
            </a>
            <a href={"/cookie-settings"}>
                <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"start"}>
                    {footerTexts.cookieSettings[langSelector.lang]}
                </Typography>
            </a>
        </Stack>

        <Typography level={"body-sm"} sx={{color: "white"}} textAlign={"center"}>
            © 2024 Hotep Pan-African News Agency. All rights reserved.
        </Typography>

    </Stack>

}

export default FooterComponent