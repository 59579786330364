import {Alert, Box, Button, Input, Link, Stack, Typography} from "@mui/joy";
import {InfoOutlined, InfoRounded, RemoveRedEyeOutlined, RemoveRedEyeRounded} from "@mui/icons-material";
import {useEffect, useMemo, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {login, resetInvalidCredentials} from "../../../store/userSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {translateToFr} from "../../../api/lang";
import authTexts from "../authTexts";

const LoginPage = () => {
    const dispatch = useAppDispatch()
    const userSelector = useAppSelector(state => state.user)
    const langSelector = useAppSelector(state => state.lang)
    const [credentials, setCredentials] = useState<{ password: string, email: string }>()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const navigate = useNavigate()
    const routerLocation = useLocation()

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()

    const handleSetCredentials = (key, value) => {
        dispatch(resetInvalidCredentials())
        setCredentials(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    const handleLogin = (e) => {
        e.preventDefault()
        dispatch(login(credentials))
    }

    useEffect(() => {
        if (userSelector.user && query.get('next'))
            navigate(query.get('next'))
        else if (userSelector.user)
            navigate("/")
    })


    return <Box component={"div"} display={"flex"} paddingY={{xs: 0, md: 5}}
                justifyContent={"center"} height={"100%"} width={"100%"}>

        <Box component={"div"} borderRadius={{xs: 0, md: 10}} paddingX={{xs: "25px", sm: "7%"}}
             sx={{backgroundColor: "white"}}
             display={"flex"} marginX={{xs: 0, md: "15%", lg: "25%"}} flexDirection={"column"} gap={2}
             width={{md: "max-content", xs: "100%"}} paddingY={8} alignItems={"center"}
             height={{xs: "100%", md: "max-content"}}>

            {query.get("reason") === "auth-required" && !userSelector.invalidCredentials &&
                <Alert variant={"soft"} color={"danger"} startDecorator={<InfoOutlined sx={{color: "inherit"}}/>}>
                    {authTexts.authRequired[langSelector.lang]}
                </Alert>}

            <Link href={"/"}>
                <img src={require("../../../assets/images/logo.jpg")} style={{height: 120, width: 120}}/>
            </Link>

            <Typography level={"h3"}>
                {authTexts.signInTitle[langSelector.lang]}
            </Typography>

            <Stack gap={1}>
                <Typography level={"body-lg"}>
                    {authTexts.noAccountLabel[langSelector.lang]} <Link
                    href={"/account/register" + routerLocation.search}>
                    {authTexts.signUpLabel[langSelector.lang]}
                </Link>
                </Typography>

                {userSelector.invalidCredentials &&
                    <Alert variant={"soft"} color={"danger"} startDecorator={<InfoOutlined sx={{color: "inherit"}}/>}>
                        {authTexts.invalidCredentials[langSelector.lang]}
                    </Alert>}
            </Stack>


            <form style={{display: "contents"}} onSubmit={handleLogin}>
                <Input variant={"outlined"} size={"lg"} placeholder={authTexts.emailAddressLabel[langSelector.lang]}
                       name={"email"}
                       type={"email"}
                       value={credentials?.email}
                       onChange={e => handleSetCredentials("email", e.target.value)}
                       fullWidth required/>

                <Input variant={"outlined"} size={"lg"} placeholder={authTexts.passwordLabel[langSelector.lang]}
                       type={passwordVisible ? "text" : "password"} name={"password"}
                       endDecorator={
                           <RemoveRedEyeOutlined
                               sx={{color: "grey", cursor: "pointer"}}
                               onClick={event => {
                                   event.preventDefault()
                                   setPasswordVisible(prevState => !prevState)
                               }}/>}
                       value={credentials?.password}
                       onChange={e => handleSetCredentials("password", e.target.value)}
                       fullWidth required/>

                <Stack direction={"row"} width={"100%"} paddingY={0.5} justifyContent={"flex-end"}>
                    <Link href={"/account/reset-password"}>
                        {authTexts.passwordForgottenLabel[langSelector.lang]}
                    </Link>

                </Stack>

                <Button size={"lg"} type={"submit"} loading={userSelector.isLoading} fullWidth>
                    {authTexts.signInLabel[langSelector.lang]}
                </Button>
            </form>

        </Box>
    </Box>
}

export default LoginPage