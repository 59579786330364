import {Box, Grid, Stack, Typography} from "@mui/joy";
import {
    ArticleComponent2,
    ArticleComponent4,
    ArticleComponent5,
    ArticleComponent6,
    ArticleComponent7
} from "../ArticleComponent";
import "./styles.css"
import Article from "../../types/article";
import {useAppSelector} from "../../hooks";
import homeTexts from "../../pages/Main/Home/homeTexts";

type LatestNewsComponentProps = {
    articles: Article[]
}

const LatestNewsComponent = (props: LatestNewsComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    return <Box component={"div"} gap={2} paddingY={2} width={"100%"}
                className={"latest-news-container"}>
        <Typography textTransform={"uppercase"} color={"primary"} textAlign={"start"}>
            {homeTexts.latestNewsLabel[langSelector.lang]}
        </Typography>
        <Stack gap={4.5}>
            {
                props.articles?.map((value, index) => index < 3 && <ArticleComponent7 article={value}/>)
            }
        </Stack>
    </Box>
}
export default LatestNewsComponent