import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Link,
    Radio,
    RadioGroup,
    Typography
} from "@mui/joy";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useEffect, useMemo, useState} from "react";
import {login, register} from "../../../store/userSlice";
import User from "../../../types/user";
import {FormControlLabel} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import authTexts from "../authTexts";

const RegisterPage = () => {
    const dispatch = useAppDispatch()
    const userSelector = useAppSelector(state => state.user)
    const langSelector = useAppSelector(state => state.lang)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [userFormData, setUserFormData] = useState<User & { name?: string }>({
        zone_id: 9,
        gender: "male"
    })
    const [confirmPassword, setConfirmPassword] = useState<string>()
    const navigate = useNavigate()
    const routerLocation = useLocation()

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()

    const handleRegister = (e) => {
        e.preventDefault()
        dispatch(register(userFormData))
    }

    useEffect(() => {
        if (userSelector.user && query.get('next'))
            navigate(query.get('next'))
        else if (userSelector.user)
            navigate("/")
    })

    return <Box component={"div"} display={"flex"} paddingY={{xs: 0, md: 5}}
                justifyContent={"center"} height={"100%"}>

        <Box component={"div"} borderRadius={{xs: 0, md: 10}} paddingX={6}
             sx={{backgroundColor: "white"}}
             display={"flex"} marginX={{xs: 0, md: "15%", lg: "30%"}} flexDirection={"column"} gap={2}
             width={{lg: "max-content", xs: "100%"}} paddingY={6} alignItems={"center"}
             height={{xs: "100%", md: "max-content"}}>

            <Link href={"/"}>
                <img src={require("../../../assets/images/logo.jpg")} style={{height: 120, width: 120}}/>
            </Link>

            <Typography level={"h3"}>
                {authTexts.signUpTitle[langSelector.lang]}
            </Typography>

            <Typography level={"body-lg"}>
                {authTexts.hasAccountLabel[langSelector.lang]} <a href={"/account/login" + routerLocation.search}
                                                                  className={"text-underlined"}>
                {authTexts.signInLabel[langSelector.lang]}
            </a>
            </Typography>

            <form style={{display: "contents"}} onSubmit={handleRegister}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6}>
                        <Input variant={"outlined"} size={"lg"} placeholder={authTexts.fullNameLabel[langSelector.lang]}
                               type={"text"} fullWidth
                               value={userFormData.name}
                               onChange={e => setUserFormData(prevState => {
                                   return {
                                       ...prevState,
                                       name: e.target.value
                                   }
                               })}
                               required/>
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <Input variant={"outlined"} size={"lg"} placeholder={authTexts.phoneLabel[langSelector.lang]}
                               type={"tel"} fullWidth
                               value={userFormData.phone}
                               onChange={e => setUserFormData(prevState => {
                                   return {
                                       ...prevState,
                                       phone: e.target.value
                                   }
                               })}
                               required/>

                    </Grid>

                    <Grid xs={12} sm={7}>
                        <Input variant={"outlined"} size={"lg"}
                               placeholder={authTexts.emailAddressLabel[langSelector.lang]}
                               type={"email"} fullWidth
                               value={userFormData.email}
                               onChange={e => setUserFormData(prevState => {
                                   return {
                                       ...prevState,
                                       email: e.target.value
                                   }
                               })}
                               required/>

                    </Grid>

                    <Grid xs={12} sm={5} alignItems={'center'}>
                        <Input variant={"outlined"} id={"dob"} size={"lg"} placeholder={"Date of birth"} type={"date"}
                               fullWidth
                               value={userFormData.date_of_birth}
                               onChange={e => setUserFormData(prevState => {
                                   return {
                                       ...prevState,
                                       date_of_birth: e.target.value
                                   }
                               })} required/>
                        <FormLabel sx={{
                            color: "neutral.500",
                            textTransform: "lowercase"
                        }}>{authTexts.dateOfBirthLabel[langSelector.lang]}</FormLabel>
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <Input variant={"outlined"} size={"lg"} placeholder={authTexts.passwordLabel[langSelector.lang]}
                               type={passwordVisible ? "text" : "password"}
                               endDecorator={
                                   <RemoveRedEyeOutlined
                                       sx={{color: "grey", cursor: "pointer"}}
                                       onClick={event => {
                                           event.preventDefault()
                                           setPasswordVisible(prevState => !prevState)
                                       }}/>}
                               fullWidth
                               value={userFormData.password}
                               onChange={e => setUserFormData(prevState => {
                                   return {
                                       ...prevState,
                                       password: e.target.value
                                   }
                               })}
                               required/>
                    </Grid>

                    <Grid xs={12} sm={6}>
                        <Input variant={"outlined"} size={"lg"}
                               placeholder={authTexts.confirmPasswordLabel[langSelector.lang]} type={"password"}
                               endDecorator={
                                   <RemoveRedEyeOutlined
                                       type={passwordVisible ? "text" : "password"}
                                       onClick={event => {
                                           event.preventDefault()
                                           setPasswordVisible(prevState => !prevState)
                                       }}
                                       sx={{color: "grey", cursor: "pointer"}}/>
                               } fullWidth
                               value={confirmPassword}
                               onChange={e => setConfirmPassword(e.target.value)}
                               required/>
                    </Grid>

                </Grid>


                <FormControl sx={{alignItems: "start", width: "100%", justifyContent: "start", marginLeft: 1}}>
                    <FormLabel
                        id="demo-row-radio-buttons-group-label">{authTexts.genderLabel[langSelector.lang]}</FormLabel>
                    <RadioGroup defaultValue={"male"} orientation={"horizontal"}
                                value={userFormData.gender}
                                onChange={e => setUserFormData(prevState => {
                                    return {
                                        ...prevState,
                                        gender: e.target.value
                                    }
                                })}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group">
                        <FormControlLabel value="male" control={<Radio/>}
                                          label={authTexts.maleLabel[langSelector.lang]}/>
                        <FormControlLabel value="female" control={<Radio/>}
                                          label={authTexts.femaleLabel[langSelector.lang]}/>
                    </RadioGroup>
                </FormControl>

                <Typography level={"body-sm"} textAlign={"start"}>
                    {authTexts.signUpDisclaimerAgreement(authTexts.termsOfUseLabel[langSelector.lang],
                        authTexts.privacyPolicyLabel[langSelector.lang])[langSelector.lang]}
                </Typography>

                <Button size={"lg"} type={"submit"} loading={userSelector.isLoading} fullWidth>
                    {authTexts.signUpLabel[langSelector.lang]}
                </Button>
            </form>

        </Box>
    </Box>
}

export default RegisterPage