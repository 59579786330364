import {Box, Grid, Stack, Typography} from "@mui/joy";
import Category from "../../types/category";
import {useEffect, useState} from "react";
import Article from "../../types/article";
import "../../components/FeaturedStories/styles.css"
import {get} from "../../api";
import {HOST} from "../../constants";
import LessFeaturedStoriesComponent from "../../components/FeaturedStories/LessFeaturedStoriesComponent";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {translateToFr} from "../../api/lang";
import categoryTexts from "./categoryTexts";

const CategoryPage = () => {
    const location = useLocation()
    const languageSelector = useAppSelector(state => state.lang)
    const categorySelector = useAppSelector(state => state.categories)
    const [category, setCategory] = useState<Category>()
    const [categoryArticles, seCategoryArticles] = useState<Article[]>()
    const mainArticle = categoryArticles?.[0]
    const articleTitleField = languageSelector.lang === "fr" ? "title_fr" : "title"
    const articleContentField = languageSelector.lang === "fr" ? "content_fr" : "content"
    const categoryNameField = languageSelector.lang === "fr" ? "name_fr" : "name"
    const articleContent = (mainArticle?.[articleContentField] || mainArticle?.content)?.substring(0, 300) + "..."


    useEffect(() => {
        get<Category>(HOST + "/category" + location.pathname.substring(location.pathname.lastIndexOf("/")))
            .then(value => setCategory(value))
            .catch(() => {
            })

        get<Article[]>(HOST + "/article?category_id=" + location.pathname.substring(location.pathname.lastIndexOf("/") + 1))
            .then(value => seCategoryArticles(value))
            .catch(() => {
            })

    }, [])

    return <Box marginTop={{xs: 8.5, sm: 14}} paddingX={{xs: "25px", lg: "15%"}} paddingY={2}>
        <Stack gap={5}>

            <Box component={"div"} width={"100%"} position={"relative"} height={130}>
                <Box src={"https://www.psri.us/psricms/wp-content/uploads/2015/12/9-cropped-760x360.jpg"}
                     width={"100%"}
                     height={"100%"} component={"img"} position={"relative"} sx={{objectFit: "cover"}}/>
                <Box component={"div"} sx={{backgroundColor: "rgba(0,0,0,0.55)"}} top={0} position={"absolute"}
                     height={"100%"}
                     width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Stack gap={0.5}>
                        {category && <Typography level={"h2"} sx={{color: "white"}}>
                            {categorySelector.categories.find(value => value.id === category.id)?.[categoryNameField] || category?.name}
                        </Typography>}

                        <Typography level={"body-md"} color={"neutral"}>
                            {category?.description}
                        </Typography>
                    </Stack>
                </Box>
            </Box>

            <Grid container>

                {
                    mainArticle ? <Grid xs={12}>
                            <a href={"/articles/" + mainArticle?.id} className={"text-normal"}>
                                <Box component={"img"} src={mainArticle?.images?.[0]?.[0]} height={"30rem"}
                                     className={"featured-stories-header-img"}/>
                                <Typography level={'h2'} textAlign={"start"}>
                                    {mainArticle?.[articleTitleField] || mainArticle.title}
                                </Typography>
                            </a>

                            {mainArticle.content &&
                                <div style={{
                                    textAlign: "start",
                                }} dangerouslySetInnerHTML={{
                                    __html: `<div>${articleContent}</div>`
                                }}/>}


                            <Stack paddingY={5} gap={4}>
                                {categoryArticles?.map((value, index) => index > 0 &&
                                    <LessFeaturedStoriesComponent article={value}/>)}
                            </Stack>

                        </Grid> :
                        <Box component={"div"} height={"50vh"} width={"100%"}>
                            <Typography level={"h2"} fontWeight={"normal"}>
                                {categoryTexts.noCategoryText[languageSelector.lang]}
                            </Typography>
                        </Box>}

            </Grid>

        </Stack>
    </Box>

}
export default CategoryPage