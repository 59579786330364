import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    Alert,
    Box,
    Button, Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Input,
    Radio,
    RadioGroup, Snackbar,
    Stack,
    Typography
} from "@mui/joy";
import {useEffect, useState} from "react";
import {FormControlLabel} from "@mui/material";
import {validateEmail, validateName} from "../../../validation";
import {get, put} from "../../../api";
import {HOST} from "../../../constants";
import {DoneOutline, DoneRounded} from "@mui/icons-material";
import {updateUser} from "../../../store/userSlice";
import User from "../../../types/user";
import {useNavigate} from "react-router-dom";
import myAccountTexts from "./myAccountTexts";
import authTexts from "../../Account/authTexts";
import {Subscription} from "../../../types/subscription";
import SubscriptionHistoryItemComponent from "./SubscriptionHistoryItemComponent";

const MyAccountPage = () => {
    const userSelector = useAppSelector(state => state.user)
    const languageSelector = useAppSelector(state => state.lang)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [userProfileInfo, setUserProfileInfo] = useState<User & { name?: string }>({name: userSelector.user?.first_name, ...userSelector.user})
    const [newPassword, setNewPassword] = useState<string>()
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
    const [activeSubscription, setActiveSubscription] = useState([])
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false)
    const [profileUpdateSuccess, setProfileUpdateSuccess] = useState(false)
    const [updatingPassword, setUpdatingPassword] = useState(false)
    const [updatingProfile, setUpdatingProfile] = useState(false)


    const [errors, setErrors] = useState({
        first_name: {
            isValid: true,
            hasEdited: false,
            errorMessage: "Please enter a valid name"
        },
        phone: {
            hasEdited: false,
            isValid: true,
            errorMessage: "Please enter a valid phone number"
        },
        email: {
            hasEdited: false,
            isValid: true,
            errorMessage: {
                default: "Enter a valid email",
                usernameUnavailable: "This username is not available,try another one.",
                usernameCheckError: "Unable to connect to server, please check your network connection and retry."
            }
        }
    })
    const [passwordErrors, setPasswordErrors] = useState({
        confirmPassword: {
            hasEdited: false,
            isValid: false,
            errorMessage: "Passwords do not match"
        }
    })


    const handleUserProfileInfoChange = (key: string, value: string | number) => {
        setUserProfileInfo(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    const loadSubscriptionData = () => {
        get<Subscription[]>(HOST + "/my-subscription", {
            headers: {
                Authorization: "Bearer " + userSelector.user?.token
            }
        }).then(value => {
            get<Subscription[]>(HOST + "/my-active-subscription", {
                headers: {
                    Authorization: "Bearer " + userSelector.user?.token
                }
            }).then(value => {
                setActiveSubscription(value)
            }).catch(reason => {
                console.log(reason)
            })
            setSubscriptions(value)
        })
            .catch(reason => {
                console.log(reason)
            })
    }


    const handleSubmit = (event) => {
        event.preventDefault()
        setUpdatingProfile(true)
        put(HOST + "/user/" + userProfileInfo.id, userProfileInfo, {
            headers: {
                Authorization: "Bearer " + userProfileInfo.token
            }
        }).then(() => {
            setUpdatingProfile(false)
            setProfileUpdateSuccess(true)
            dispatch(updateUser({
                first_name: userProfileInfo.name,
                ...userProfileInfo
            }))
        }).catch(reason => {
            console.log(reason)
            setUpdatingProfile(false)
            setProfileUpdateSuccess(false)
        })
    }

    const handleUpdatePassword = (event) => {
        event.preventDefault()
        setUpdatingPassword(true)
        put(HOST + "/user/" + userProfileInfo.id, {password: newPassword}, {
            headers: {
                Authorization: "Bearer " + userProfileInfo.token
            }
        }).then(() => {
            setUpdatingPassword(false)
            setPasswordUpdateSuccess(true)
        }).catch(reason => {
            console.log(reason)
            setUpdatingPassword(false)
            setPasswordUpdateSuccess(false)
        })
    }

    const handleCloseSnackbar = () => {
        setPasswordUpdateSuccess(false)
        setProfileUpdateSuccess(false)
    }

    useEffect(() => {
        if (userProfileInfo) {
            setErrors(prevState => {
                return {
                    phone: {
                        ...prevState.phone,
                        hasEdited: prevState.phone.hasEdited || !!userProfileInfo.phone,
                        isValid: prevState.phone.hasEdited ? userProfileInfo.phone?.length === 9 : true
                    },
                    first_name: {
                        ...prevState.first_name,
                        hasEdited: prevState.first_name.hasEdited || !!userProfileInfo.first_name,
                        isValid: prevState.first_name.hasEdited ? validateName(userProfileInfo.first_name) : true
                    },
                    email: {
                        ...prevState.email,
                        hasEdited: prevState.email.hasEdited || !!userProfileInfo.email,
                        isValid: prevState.email.hasEdited ? validateEmail(userProfileInfo.email) : true
                    }
                }
            })
        }
    }, [userProfileInfo])

    useEffect(() => {
        setPasswordErrors(prevState => {
            return {
                confirmPassword: {
                    hasEdited: prevState.confirmPassword.hasEdited || !!newPasswordConfirm,
                    isValid: prevState.confirmPassword.hasEdited ? newPasswordConfirm === newPassword : true,
                    errorMessage: prevState.confirmPassword.errorMessage
                }
            }
        })
    }, [newPassword, newPasswordConfirm])

    useEffect(() => loadSubscriptionData(), [])

    useEffect(() => {
        if (!userSelector.user)
            return navigate("/account/login")
    })

    return <Box component={"div"} display={"flex"} flexDirection={"column"}
                paddingX={{xs: "25px", lg: "15%"}} marginTop={{xs: 8.5, sm: 10}}
                paddingY={8} gap={2} boxSizing={"border-box"}>
        <Stack>
            <Typography level={"h2"} textAlign={"start"}>
                {myAccountTexts.myAccountTitle[languageSelector.lang]}
            </Typography>
            <Typography level={"body-md"} color={"neutral"} textAlign={"start"}>
                {myAccountTexts.myAccountDescription[languageSelector.lang]}
            </Typography>
        </Stack>

        <Stack width={"100%"}>
            <Typography level={"h4"} textAlign={"start"}>
                {myAccountTexts.personalInfoLabel[languageSelector.lang]}
            </Typography>


            <Grid container columnSpacing={5}>
                <Grid paddingBottom={3} sm={6} xs={12}>
                    <Box component={"form"} onSubmit={handleSubmit} width={"100%"} display={"flex"}
                         flexDirection={"column"}
                         justifyContent={"start"} gap={2}>
                        <Grid xs={12} spacing={2}>
                            <Stack gap={1}>
                                <Typography level={"title-md"} textAlign={"start"}>
                                    {authTexts.fullNameLabel[languageSelector.lang]}:
                                </Typography>
                                <Input fullWidth value={userProfileInfo.name}
                                       placeholder={authTexts.fullNameLabel[languageSelector.lang]} title={"Full Name"}
                                       required
                                       onChange={event => handleUserProfileInfoChange("name", event.target.value)}/>
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <Stack gap={1}>
                                <Typography level={"title-md"} textAlign={"start"}>
                                    {authTexts.emailAddressLabel[languageSelector.lang]}
                                </Typography>
                                <Input fullWidth value={userProfileInfo.email}
                                       placeholder={authTexts.emailAddressLabel[languageSelector.lang]} title={"Email"}
                                       type={"email"} required
                                       onChange={event => handleUserProfileInfoChange("email", event.target.value)}/>
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <Stack gap={1}>
                                <Typography level={"title-md"} textAlign={"start"}>
                                    {authTexts.phoneLabel[languageSelector.lang]}
                                </Typography>
                                <Input fullWidth value={userProfileInfo.phone}
                                       placeholder={"Phone"} title={"Phone"} type={"tel"} required
                                       onChange={event => handleUserProfileInfoChange("phone", event.target.value)}/>
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <Stack gap={1}>
                                <Typography level={"title-md"} textAlign={"start"}>
                                    {authTexts.dateOfBirthLabel[languageSelector.lang]}
                                </Typography>
                                <Input fullWidth value={userProfileInfo.date_of_birth} required
                                       placeholder={"Date of birth"} title={"Date of birth"} type={"date"}
                                       onChange={event => handleUserProfileInfoChange("date_of_birth", event.target.value)}/>
                            </Stack>
                        </Grid>

                        <Grid xs={12}>
                            <FormControl
                                sx={{alignItems: "start", width: "100%", justifyContent: "start", marginLeft: 1}}>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    {authTexts.genderLabel[languageSelector.lang]}
                                </FormLabel>
                                <RadioGroup defaultValue={userProfileInfo.gender} orientation={"horizontal"}
                                            value={userProfileInfo.gender}
                                            onChange={e => handleUserProfileInfoChange("gender", e.target.value)}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group">
                                    <FormControlLabel value="female" control={<Radio/>}
                                                      label={authTexts.femaleLabel[languageSelector.lang]}/>
                                    <FormControlLabel value="male" control={<Radio/>}
                                                      label={authTexts.maleLabel[languageSelector.lang]}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>


                        <Grid xs={12} display={"flex"} justifyContent={"start"}>
                            <Button type={"submit"} loading={updatingProfile}>
                                Update profile
                            </Button>
                        </Grid>

                    </Box>

                </Grid>


                <Grid sm={6} xs={12} paddingBottom={3}>
                    <Stack width={"100%"}>
                        <Typography level={"h4"} textAlign={"start"}>
                            {myAccountTexts.updatePasswordLabel[languageSelector.lang]}
                        </Typography>

                        <Box component={"form"} onSubmit={handleUpdatePassword} gap={2} display={"flex"}
                             flexDirection={"column"} width={"100%"}>
                            <FormControl>
                                {<FormHelperText color={""}>
                                    <Input fullWidth value={newPassword}
                                           placeholder={myAccountTexts.newPasswordLabel[languageSelector.lang]}
                                           title={myAccountTexts.newPasswordLabel[languageSelector.lang]}
                                           type={"password"}
                                           required
                                           onChange={event => setNewPassword(event.target.value)}/>
                                </FormHelperText>}
                            </FormControl>

                            <FormControl>
                                <Input fullWidth value={newPasswordConfirm}
                                       placeholder={myAccountTexts.confirmNewPasswordLabel[languageSelector.lang]}
                                       title={myAccountTexts.confirmNewPasswordLabel[languageSelector.lang]}
                                       type={"password"}
                                       required
                                       onChange={event => setNewPasswordConfirm(event.target.value)}/>
                                {!passwordErrors.confirmPassword.isValid &&
                                    <FormHelperText color={"error"}>
                                        {passwordErrors.confirmPassword.errorMessage}
                                    </FormHelperText>}
                            </FormControl>

                            <Box component={"div"} display={"flex"} justifyContent={"start"}>
                                <Button disabled={!passwordErrors.confirmPassword.isValid} loading={updatingPassword}
                                        type={"submit"}>
                                    {myAccountTexts.updatePasswordLabel[languageSelector.lang]}
                                </Button>
                            </Box>

                        </Box>
                    </Stack>

                </Grid>

                <Grid xs={12}>
                    <Stack paddingY={2} gap={1}>
                        <Typography level={"h4"} textAlign={"start"}>
                            {myAccountTexts.subscriptionTitle[languageSelector.lang]}
                        </Typography>
                        <Stack gap={1.5}>
                            <Stack direction={"row"} alignItems={"center"} width={"100%"}
                                   justifyContent={"space-between"}>
                                <Typography level={"h4"}
                                            color={"primary"}>{activeSubscription.length === 0 ? myAccountTexts.noActiveSubscription[languageSelector.lang]
                                    : activeSubscription[0].name}</Typography>
                                <a href={"/subscription"}>
                                    <Button
                                        sx={{borderRadius: 11}}>{activeSubscription.length === 0 ? myAccountTexts.subscribeLabel[languageSelector.lang] : myAccountTexts.upgradeSubscriptionLabel[languageSelector.lang]}
                                    </Button>
                                </a>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack paddingY={2} gap={1}>
                        <Typography level={"h4"} textAlign={"start"}>
                            {myAccountTexts.subscriptionHistory[languageSelector.lang]}
                        </Typography>
                        <Stack paddingX={"10"}>
                            {subscriptions.map(value => <SubscriptionHistoryItemComponent subscription={value}
                                                                                          isActive={activeSubscription[0]?.id === value.id}/>)}
                        </Stack>

                    </Stack>

                </Grid>

            </Grid>

        </Stack>

        <Snackbar open={passwordUpdateSuccess} size={"sm"} variant={"solid"} color={"success"}
                  startDecorator={<DoneRounded sx={{color: "inherit"}}/>}
                  autoHideDuration={2500} onClose={handleCloseSnackbar}>
            {myAccountTexts.passwordUpdateSuccessLabel[languageSelector.lang]}
        </Snackbar>

        <Snackbar open={profileUpdateSuccess} size={"sm"} variant={"solid"} color={"success"}
                  startDecorator={<DoneRounded sx={{color: "inherit"}}/>}
                  autoHideDuration={2500} onClose={handleCloseSnackbar}>
            {myAccountTexts.profileUpdateSuccessLabel[languageSelector.lang]}
        </Snackbar>

    </Box>
}

export default MyAccountPage