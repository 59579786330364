import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import User from "../types/user";
import Article from "../types/article";
import {get} from "../api";
import {HOST} from "../constants";
import Category from "../types/category";


export const loadIndex = createAsyncThunk("article/fetchBreakingNews", async () => {
    return await get<any>(HOST + "/dashboard")
})

const initialData: {
    isLoading?: boolean,
    featuredNews?: Article[], breakingNews?: Article[], topStories?: Article[],
    featuredCategories?: {
        category: Category,
        feature_news: Article[]
    }[]
} = {
    featuredNews: []
}

const articles = createSlice({
    name: "articles",
    initialState: initialData,
    reducers: {
        resetUser: (state) => {
            state = initialData
        },
    },
    extraReducers: builder => {
        builder.addCase(loadIndex.fulfilled, (state, action) => {
            state.isLoading = false
            state.breakingNews = action.payload['breaking_news'] as Article[]
            state.featuredNews = action.payload['featured_news'] as Article[]
            state.topStories = action.payload['top_stories'] as Article[]
            state.featuredCategories = action.payload['categories'] as {
                category: Category,
                feature_news: Article[]
            }[]
        })

        builder.addCase(loadIndex.pending, (state, action) => {
            state.isLoading = true
        })


        builder.addCase(loadIndex.rejected, (state, action) => {
            state.isLoading = false
            console.log(action.error.message)
        })
    }
})

export const {} = articles.actions
export default articles.reducer