const subscriptionTexts = {
    subscribeTitle: {
        en: "Subscribe Hotep Pan-African Agency Media Press Services",
        fr: "Souscrire à HOPNA"
    },
    subscriptionDescription: {
        en: "Do not miss any info,live updates, access to top stories and more. Choose the subscription that most suites you.",
        fr: "Ne manquez aucune  information de qualité et bien plus encore. Choisissez la souscription qui vous convient le mieux"
    },
    basicPlanFunctionality1: {
        en: "Bantou Club Moderator",
        fr: "Modérateur Bantou Club",
    },
    basicPlanFunctionality2: {
        en: "Basic access to articles",
        fr: "Accès limité aux articles",
    },
    basicPlanFunctionality3: {
        en: "Top Tier Information Access",
        fr: "Accès à des informations de qualité",
    },
    advancedPlanFunctionality1: {
        en: "All Access Features",
        fr: "Accès à toutes les fonctionalitès Access",
    },
    advancedPlanFunctionality2: {
        en: "Personalized Newsletter",
        fr: "Newsletter Personalisé",
    },
    advancedPlanFunctionality3: {
        en: "Top Toer access to Bantou Club",
        fr: "Accès avancé au Club Bantou",
    },
    premiumFunctionality1: {
        en: "All Advanced Features",
        fr: "Accès à toutes les fonctionalités Advanced",
    },
    premiumFunctionality2: {
        en: "30% Off on Advertisement",
        fr: "30% de réduction sur les publicités",
    },
    monthlyLabel: {
        en: "Month",
        fr: "Par mois",
    },
    termlyLabel: {
        en: "3 months",
        fr: "Par Trimèstre (3 mois)"
    },
    yearlyLabel: {
        en: "Year",
        fr: "Par an"
    }
}

export default subscriptionTexts