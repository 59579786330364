import {Avatar, Stack, Typography} from "@mui/joy";
import {Subscription} from "../../../types/subscription";
import {BadgeOutlined, MonetizationOn, MonetizationOnOutlined} from "@mui/icons-material";

type SubscriptionHistoryItemComponentProps = {
    subscription?: Subscription,
    isActive?: boolean
}
const SubscriptionHistoryItemComponent = (props: SubscriptionHistoryItemComponentProps) => {
    return <Stack direction={"row"} gap={2}>
        <Avatar sx={{backgroundColor: "rgba(163,77,2,0.69)"}}>
            <MonetizationOn name={"badge"}/>
        </Avatar>

        <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack gap={1}>
                <Typography level={"title-sm"}>
                    {props.subscription.name}
                </Typography>
                <Typography level={"body-sm"}>
                    {new Date(props.subscription.created_at).toLocaleDateString()}
                </Typography>
            </Stack>

            <Stack gap={1}>
                <Typography level={"body-md"}>
                    €{props.subscription.price.toString().replace(".", ",")}
                </Typography>
            </Stack>

        </Stack>
    </Stack>
}

export default SubscriptionHistoryItemComponent