import {Box, Typography} from "@mui/joy";
import Comment from "../../../types/comment";
import User from "../../../types/user";

type BantouClubCommentComponentProps = {
    comment?: Comment,
    isMyComment?: boolean
}

const BantouClubCommentComponent = (props: BantouClubCommentComponentProps) => {
    return <Box component={"div"} my={1.5} mr={4} px={2} display={"flex"}
                justifyContent={props.isMyComment ? "right" : "left"}
                alignItems={"center"}>

        <Box component={"div"} minWidth={"25%"} maxWidth={"60%"}>
            <Box component={"div"} width={"100%"}
                 sx={{
                     backgroundColor: props.isMyComment ? "primary.500" : "#e0e0e0",
                     color: props.isMyComment ? "white" : "#505050"
                 }} borderRadius={15} paddingX={2} paddingY={2}>
                <Typography level={"body-lg"} sx={{color: "inherit", fontWeight: "500"}} textAlign={"start"}>
                    {props.comment.text}
                </Typography>
            </Box>
            <Typography color={"neutral"} level={"body-md"} textAlign={props.isMyComment ? "end" : "start"}>
                {(props.comment.user as User).first_name}
            </Typography>
        </Box>

    </Box>
}

export default BantouClubCommentComponent