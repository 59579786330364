import {Box, Button, FormControl, FormHelperText, Input, Snackbar, Typography} from "@mui/joy";
import {post} from "../../../api";
import {HOST} from "../../../constants";
import {useState} from "react";
import {DoneRounded} from "@mui/icons-material";
import {useAppSelector} from "../../../hooks";
import authTexts from "../authTexts";

const ResetPasswordPage = () => {
    const [email, setEmail] = useState("")
    const [sendingRequest, setSendingRequest] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const langSelector = useAppSelector(state => state.lang)

    const handleSubmit = (event) => {
        event.preventDefault()
        setSendingRequest(true)
        post(HOST + "/forgot-password", {email})
            .then(() => {
                setEmail("")
                setSendingRequest(false)
                setRequestSent(true)
            }).catch(reason => {
            console.log(reason)
            setSendingRequest(false)
        })
    }

    return <Box component={"div"} display={"flex"} paddingY={{xs: 0, md: 5}}
                justifyContent={"center"} height={"100%"} width={"100%"}>

        <Box component={"div"} borderRadius={{xs: 0, md: 10}} paddingX={{xs: "25px", sm: "7%"}}
             sx={{backgroundColor: "white"}}
             display={"flex"} marginX={{xs: 0, md: "15%", lg: "25%"}} flexDirection={"column"} gap={2}
             width={{md: "max-content", xs: "100%"}} paddingY={8} alignItems={"center"}
             height={{xs: "100%", md: "max-content"}}>
            <Box component={"form"} onSubmit={handleSubmit} display={"flex"} flexDirection={"column"}
                 gap={4}>
                <Box component={"div"} display={"flex"} justifyContent={"center"}>
                    <img src={require("../../../assets/images/logo.jpg")} style={{height: 120, width: 120}}/>
                </Box>
                <Typography level={"h3"} textAlign={"start"}>
                    {authTexts.resetPasswordTitle[langSelector.lang]}
                </Typography>
                <FormControl>
                    <Input title={"Email"} placeholder={authTexts.emailAddressLabel[langSelector.lang]}
                           value={email}
                           onChange={event => setEmail(event.target.value)} type={"email"}
                           required/>
                    <FormHelperText>
                        {authTexts.resetPasswordHelperText[langSelector.lang]}
                    </FormHelperText>
                </FormControl>
                <Button type={"submit"} loading={sendingRequest}>
                    {authTexts.resetPasswordLabel[langSelector.lang]}
                </Button>
            </Box>
        </Box>
        <Snackbar open={requestSent} autoHideDuration={2500} onClose={() => setRequestSent(false)}
                  startDecorator={<DoneRounded/>}
                  size={"md"} variant={"solid"} color={"success"}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            {authTexts.resetPasswordSuccessText[langSelector.lang]}
        </Snackbar>
    </Box>
}
export default ResetPasswordPage
