import {Box} from "@mui/joy";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {get} from "../../../api";
import {Subscription} from "../../../types/subscription";
import {HOST} from "../../../constants";
import {useAppSelector} from "../../../hooks";

const LiveEventMeetingPage = () => {
    const navigate = useNavigate()

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()
    const iframeSrc = query.get("url")

    if (!iframeSrc)
        navigate("/live-events")

    return <Box component={"div"} display={"flex"} flexDirection={"column"}
                paddingX={{xs: "25px", lg: "15%"}} marginTop={{xs: 8.5, sm: 10}}
                gap={2} boxSizing={"border-box"} height={"100%"}>

        {iframeSrc && <Box component={"iframe"} src={iframeSrc}
                           name="iframe_a" paddingY={2}
                           height={"100vh"} width={"100%"} title="Iframe Example"></Box>}
    </Box>
}

export default LiveEventMeetingPage