import {Box, Stack, Typography} from "@mui/joy";
import "./styles.css"
import LessFeaturedStoriesComponent from "./LessFeaturedStoriesComponent";
import Article from "../../types/article";
import {useAppSelector} from "../../hooks";
import homeTexts from "../../pages/Main/Home/homeTexts";

type FeaturedStoriesComponentProps = {
    articles: Article[]
}

const FeaturedStoriesComponent = (props: FeaturedStoriesComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const featuredStoriesHeader = props.articles?.[0]
    const otherFeaturedArticles = props.articles?.filter((value, index) => index !== 0 && index < 6)

    return <Box component={"div"} width={"100%"}>
        <Stack gap={2} height={"max-content"} paddingY={2}>
            <Typography textTransform={"uppercase"} color={"primary"} textAlign={"start"}>{homeTexts.featuredStoriesLabel[langSelector.lang]}</Typography>
            <a href={"/articles/" + featuredStoriesHeader?.id} className={"text-normal"}>
                <img src={featuredStoriesHeader?.images?.[0]?.[0]}
                     className={"featured-stories-header-img"}/>
                <Typography level={'h3'} textAlign={"start"}>
                    {featuredStoriesHeader?.title}
                </Typography>
            </a>

            <Stack paddingTop={2} gap={3}>
                {otherFeaturedArticles.map(value => <LessFeaturedStoriesComponent article={value}/>)}
            </Stack>

        </Stack>
    </Box>
}
export default FeaturedStoriesComponent