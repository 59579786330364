import {Box, Button, Divider, Grid, Input, Stack, Typography} from "@mui/joy";
import {ArticleComponent1, ArticleComponent2} from "../ArticleComponent";
import "./styles.css"
import {useEffect, useState} from "react";
import Debate from "../../types/debate";
import {get} from "../../api";
import {HOST} from "../../constants";
import User from "../../types/user";
import {PersonRounded} from "@mui/icons-material";
import {useAppSelector} from "../../hooks";
import homeTexts from "../../pages/Main/Home/homeTexts";


const BantouClubComponent = () => {
    const langSelector = useAppSelector(state => state.lang)
    const [debates, setDebates] = useState<Debate[]>([])
    const articleTitleField = langSelector.lang === "fr" ? "title_fr" : "title"

    useEffect(() => {
        get<Debate[]>(HOST + "/forum", {}).then(value => {
            setDebates(value)
        }).catch(() => {
        })
    }, [])

    if (debates.length > 0)
        return <Box component={"div"} width={"100%"} className={"bantou-club-container"}>
            <Typography textTransform={"uppercase"} color={"primary"} textAlign={"start"}>Bantù Club </Typography>
            <table>
                <tbody>
                <tr>
                    <th>
                        <Typography level={"title-md"} sx={{color: "white"}}>
                            {homeTexts.topicOfTheDayLabel[langSelector.lang]}
                        </Typography>
                    </th>
                </tr>

                <tr>
                    <td>
                        <Stack gap={2}>
                            <a href={"/bantou-club-debates?debate_id=" + debates[0]?.id} className={"text-normal"}>
                                <Typography level={"h3"} textAlign={"start"}>
                                    {debates[0]?.[articleTitleField] || debates[0]?.title}
                                </Typography>
                            </a>

                            <Stack gap={1}>
                                {debates[0]?.latestComments.map((value, index) =>
                                    index < 2 &&
                                    <Typography level={"body-sm"} color={"neutral"} textAlign={"start"}
                                                startDecorator={<PersonRounded
                                                    sx={{color: "inherit", fontSize: "md"}}/>}>
                                        <b>{(value.user as User).first_name} </b>: {value.text}
                                    </Typography>
                                )}
                            </Stack>

                        </Stack>
                    </td>
                </tr>

                <tr>
                    <th>
                        <Typography level={"title-md"} sx={{color: "white"}}>
                            {homeTexts.featuredBantouClubTopicsLabel[langSelector.lang]}
                        </Typography>
                    </th>
                </tr>

                <tr>
                    <td>
                        {debates.map((value, index) =>
                            index > 0 && index < 4 &&
                            <Stack gap={2} paddingY={2.5}>
                                <a href={"/bantou-club-debates?debate_id=" + value.id} className={"text-normal"}>
                                    <Typography level={"title-sm"} textAlign={"start"}>
                                        {value[articleTitleField] || value.title}
                                    </Typography>
                                </a>
                            </Stack>)}
                        <Stack paddingY={2}>
                            <a href={"/bantou-club-debates"}><Button fullWidth>
                                {homeTexts.seeMoreLabel[langSelector.lang]}
                            </Button></a>
                        </Stack>
                    </td>
                </tr>

                </tbody>
            </table>
        </Box>
}

export default BantouClubComponent