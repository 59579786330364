const liveEventTexts = {
    description: {
        en: "We accompany you onm the realization, dissemination and animation of your physical, digital or hybrid events.",
        fr: "Nous vous accompagnons sur la réalisation, la diffusion et l'animation de vos évènements physiques, digitaux ou hybrides."
    },
    title: {
        en: "Live Event, the complete event offer to manage all your needs and make your event a success.",
        fr: "Live Event, l'offre évènementielle complète pour gérer tous vos besoins et faire de votre évènement un succès."
    }
}

export default liveEventTexts