import {LiveEvent} from "../../../types/event";
import {Box, Link, Stack, Typography} from "@mui/joy";
import {
    AlarmOn, CalendarMonthOutlined,
    LinkOutlined,
    LocationOnOutlined,
    LockClockOutlined,
    PunchClock, TimelapseOutlined,
    Timeline,
    Timer, Watch
} from "@mui/icons-material";
import {useEffect, useState} from "react";

const LiveEventItemComponent = (props: { event: LiveEvent, onSetIframeSrc?: (src: string) => void }) => {
    return <Stack>
        <Stack direction={{xs: "column", md: "row"}} alignItems={"center"} gap={3}>

            <Box component={"img"} src={props.event?.media} width={150}
                 height={150} sx={{resizeMode: "cover"}}/>
            <Stack gap={1}>
                <Typography level={"title-sm"} textAlign={"start"}>
                    {props.event?.title}
                </Typography>

                <Typography level={"body-md"} textAlign={"start"}>
                    {props.event?.description}
                </Typography>

                <Typography level={"body-sm"} startDecorator={<LocationOnOutlined sx={{color: "primary.500"}}/>}>
                    {props.event?.location}
                </Typography>

                <Typography level={"body-sm"} color={"primary"}
                            startDecorator={<LinkOutlined sx={{color: "primary.500"}}/>}>
                    <Link href={props.event?.url} onClick={(event) => {
                        event.preventDefault()
                        props.onSetIframeSrc(props.event.url)
                    }}>
                        {props.event?.url}
                    </Link>
                </Typography>

                <Typography level={"body-sm"} startDecorator={<CalendarMonthOutlined sx={{color: "primary.500"}}/>}>
                    {new Date(props.event?.date_debut).toLocaleDateString()} - {new Date(props.event?.date_fin).toLocaleDateString()}
                </Typography>

            </Stack>
        </Stack>

    </Stack>
}

export default LiveEventItemComponent