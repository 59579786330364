import Article from "../../types/article";
import "./styles.css"
import {Box, Button, Divider, Grid, Link, Stack, Typography} from "@mui/joy";
import {Dialog} from "@mui/material";
import LessFeaturedStoriesComponent from "../../components/FeaturedStories/LessFeaturedStoriesComponent";
import {ArticleComponent4, ArticleComponent5} from "../../components/ArticleComponent";
import {FacebookSharp, Instagram, LinkedIn, LinkOutlined, Mail, WhatsApp, X} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {get} from "../../api";
import {HOST, SHARE_HOST} from "../../constants";
import {useAppSelector} from "../../hooks";
import articleTexts from "./articleTexts";
import {Subscription} from "../../types/subscription";

const ArticlePage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const userSelector = useAppSelector(state => state.user)
    const langSelector = useAppSelector(state => state.lang)
    const [article, setArticle] = useState<Article>({})
    const [nextArticles, setNextArticles] = useState<Article[]>([])
    const [moreArticles, setMoreArticles] = useState<Article[]>([])
    const [activeSubscription, setActiveSubscription] = useState<Subscription[]>([])
    const [showFullArticle, setShowFullArticle] = useState(false)
    const shareLink = SHARE_HOST + location.pathname
    const authorizedRoles = ["admin", "publisher", "moderator", "validator", "writer"]
    const articleContentField = langSelector.lang === "fr" ? "content_fr" : "content"
    const articleContent = showFullArticle ? (article?.[articleContentField] || article?.content) : (article?.[articleContentField] || article?.content)?.substring(0, 300) + "..."

    useEffect(() => {
        if (!userSelector.user)
            return navigate("/account/login?reason=auth-required")

        get<Article[]>(HOST + "/article?id_featured=1")
            .then(value => setNextArticles(value)).catch(() => {
        })

        get<Article[]>(HOST + "/article?is_top_story=1")
            .then(value => setMoreArticles(value)).catch(() => {
        })

        get<Article>(HOST + "/article" + location.pathname.substring(location.pathname.lastIndexOf("/")))
            .then(value => setArticle(value)).catch(() => {
        })

        get<Subscription[]>(HOST + "/my-active-subscription", {
            headers: {
                Authorization: "Bearer " + userSelector.user?.token
            }
        }).then(value => {
            setActiveSubscription(value)
        }).catch(() => {
        })


    }, [])

    const handleShowFullArticle = () => {
        if (activeSubscription.length === 0 && !userSelector.user.role?.find(role => authorizedRoles.includes(role.name)))
            navigate("/subscription")
        else setShowFullArticle(true)
    }

    useEffect(() => {
        if (article.title)
            document.title = "Hopna - " + article.title
    }, [article])

    return <Box display={"flex"} flexDirection={"column"} className={"app-child-container"} gap={3} marginTop={12}
                marginBottom={4}
                paddingY={4} paddingX={{xs: "25px", lg: "15%"}}>

        <Stack alignItems={"start"} gap={2}>
            <Typography level={"h1"} textAlign={"start"}>
                {langSelector.lang === "en" ? article.title : (article['title_fr'] || article.title)}
            </Typography>
            <Stack>
                <Typography color={"neutral"}
                            textAlign={"start"}>{articleTexts.authorLabel[langSelector.lang]} {article.users?.[0].first_name || "Zenith Takougoum"}</Typography>
                {article.published_at &&
                    <Typography textAlign={"center"}
                                color={"neutral"}>
                        {articleTexts.publishedDateLabel[langSelector.lang]} : {new Date(article.published_at).toDateString()}, {new Date(article.published_at).toLocaleTimeString()}
                    </Typography>}
            </Stack>
        </Stack>

        <Stack flexDirection={"row"} gap={1.5}>

            <Link href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`} target={"_blank"}>
                <FacebookSharp sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>
            </Link>

            <Link href={`https://x.com/share?text=${article?.title + " - Hopna Agency"}&url=${shareLink}`}
                  target={"_blank"}>
                <X sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>
            </Link>


            <Link href={`https://www.instagram.com`} target={"_blank"}>
                <Instagram sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>
            </Link>


            <Link
                href={`whatsapp://send?text=${langSelector.lang === "en" ? article.title : (article['title_fr'] || article.title)} -${shareLink}`}
                target={"_blank"} data-action="share/whatsapp/share">
                <WhatsApp sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>
            </Link>


            <Link href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`} target={"_blank"}>
                <LinkedIn sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>

            </Link>

            <a href={`mailto:edition@hopna.com?subject=${langSelector.lang === "en" ? article.title : (article['title_fr'] || article.title)}&body=${articleTexts.shareArticleText[langSelector.lang]}\n ${shareLink}`}>
                <Mail sx={theme => {
                    return {
                        color: theme.palette.primary[500]
                    }
                }}/>
            </a>
            <LinkOutlined onClick={() => {
                new Clipboard().writeText("https://hopna-agency.media" + location.pathname)
                    .then(() => {
                        console.log("Copied")
                    })
            }} sx={theme => {
                return {
                    cursor: "pointer",
                    color: theme.palette.primary[500]
                }
            }}/>
        </Stack>


        <Grid container spacing={5}>
            <Grid md={12} lg={6}>
                <Stack gap={4} marginY={4}>
                    <img className={"article-page-img"}
                         src={article.images?.[0]?.[0]}/>

                    <Stack>
                        {article.content &&
                            <div style={{
                                textAlign: "start",
                            }} dangerouslySetInnerHTML={{
                                __html: `<div>${articleContent}</div>`
                            }}/>}

                        {!showFullArticle && <Divider sx={{'--Divider-childPosition': '50%'}}>
                            <Button onClick={handleShowFullArticle}
                                    sx={{borderRadius: 20, paddingX: 10, height: 10}}>
                                {articleTexts.readFullArticle[langSelector.lang]}
                            </Button>
                        </Divider>
                        }
                    </Stack>

                </Stack>
            </Grid>


            <Grid display={{md: "none", lg: "block"}} lg={6}>
                <Stack paddingX={3} gap={2}>
                    <Box component={"div"} className={"article-story-title-container"}>
                        <Typography textTransform={"uppercase"}
                                    sx={{
                                        color: "inherit"
                                    }}
                                    textAlign={"start"}>{articleTexts.moreStoriesLabel[langSelector.lang]}</Typography>
                    </Box>
                    {
                        moreArticles.map((value, index) => (index > 0 && index < 4) &&
                            <ArticleComponent5 article={value}/>)
                    }
                </Stack>

            </Grid>

            <Grid xs={12} lg={8}>

                <Stack gap={2} width={"100%"}>
                    <Box component={"div"} className={"article-story-title-container"}>
                        <Typography textAlign={"start"} level={"h4"} sx={{color: "inherit"}}>
                            {articleTexts.nextArticleReadLabel[langSelector.lang]}
                        </Typography>
                    </Box>

                    {nextArticles.map((value, index) =>
                        (index > 0 && index < 5) && <LessFeaturedStoriesComponent article={value}/>)}

                </Stack>
            </Grid>

        </Grid>

    </Box>
}

export default ArticlePage