import {Box, Button, Divider, Grid, Input, Link, Snackbar, Stack, Textarea, Typography} from "@mui/joy";
import {useEffect, useMemo, useState} from "react";
import "./styles.css"
import {
    ChevronLeftRounded,
    CommentOutlined, FacebookSharp, InfoOutlined, Instagram, LinkedIn,
    SendRounded, ShareRounded,
    ThumbDownOutlined,
    ThumbUpOutlined, X
} from "@mui/icons-material";
import Article from "../../../types/article";
import {get, post} from "../../../api";
import Debate from "../../../types/debate";
import LoadingComponent from "../../../components/LoadingComponent";
import {HOST, SHARE_HOST} from "../../../constants";
import BantouClubCommentComponent from "./BantouClubCommentComponent";
import {useAppSelector} from "../../../hooks";
import Comment from "../../../types/comment";
import User from "../../../types/user";
import {useLocation, useNavigate} from "react-router-dom";
import bantouClubTexts from "./bantouClubTexts";

const BantouClubPage = () => {
    const userSelector = useAppSelector(state => state.user)
    const languageSelector = useAppSelector(state => state.lang)
    const navigate = useNavigate()
    const location = useLocation()
    const shareLink = SHARE_HOST + location.pathname
    const articleTitleField = languageSelector.lang === "fr" ? "title_fr" : "title"

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery()
    const [currentTopic, setCurrentTopic] = useState<Debate>()
    const [debates, setDebates] = useState<Debate[]>()
    const [comment, setComment] = useState<string>()
    const [debateComments, setDebateComments] = useState<Comment[]>([])
    const [submittingComment, setSubmittingComment] = useState(false)
    const [loadingComments, setLoadingComments] = useState(false)


    const handleSubmitComment = (e) => {
        e.preventDefault()
        if (!userSelector.user)
            navigate("/account/login?reason=auth-required&&next=" + location.pathname + location.search)

        setSubmittingComment(true)
        post(HOST + "/forum/comment/" + currentTopic.id, {text: comment}, {
            headers: {
                "Authorization": "Bearer " + userSelector.user?.token
            }
        })
            .then(() => {
                setSubmittingComment(false)
                setDebateComments(prevState => {
                    const commentsSlice = prevState.slice()
                    commentsSlice.push({
                        user: userSelector.user,
                        text: comment,
                        time: new Date().getTime()
                    })
                    return commentsSlice
                })
                setComment("")
            }).catch(() => {
            setSubmittingComment(false)
        })
    }

    const handleLike = (debateId) => {
        post(HOST + "/forum/like/" + debateId, {})
            .then(() => {
                setDebates(prevState => {
                    const debatesSlice = prevState.slice()
                    const debateIndex = debatesSlice.findIndex(value => value.id === debateId)
                    debatesSlice[debateIndex] = {
                        ...debatesSlice[debateIndex],
                        like_count: debatesSlice[debateIndex].like_count + 1
                    }
                    return debatesSlice
                })
            }).catch(() => {
        })
    }


    useEffect(() => {
        if (currentTopic) {
            setLoadingComments(true)
            get<Comment[]>(HOST + "/forum/all-comment/" + currentTopic.id)
                .then(value => {
                    setLoadingComments(false)
                    setDebateComments(value)
                })
                .catch(() => {
                    setLoadingComments(false)
                })
        }
    }, [currentTopic])

    useEffect(() => {
        get<Debate[]>(HOST + "/forum", {}).then(value => {
            setDebates(value)
            if (query.get("debate_id"))
                setCurrentTopic(value.find(debate => debate.id === parseInt(query.get("debate_id"))))
        }).catch(() => {
        })
    }, [])

    return debates ? <Box component={"div"} display={"flex"} flexDirection={"column"}
                          paddingX={{xs: "25px", lg: "15%"}} marginTop={{xs: 8.5, sm: 10}}
                          paddingY={4} gap={2} height={"100vh"} boxSizing={"border-box"}>
            <Stack>
                <Typography level={"h2"} textAlign={"start"}>
                    Club Bantu
                </Typography>
                <Typography level={"body-md"} color={"neutral"} textAlign={"start"}>
                    {bantouClubTexts.bantouClubDescription[languageSelector.lang]}
                </Typography>
            </Stack>

            <Grid container height={"80%"}>
                <Grid xs={12} md={4} lg={4} height={"100%"} display={{xs: currentTopic ? "none" : "block", md: "block"}}>
                    <Stack sx={{
                        borderColor: "primary.100",
                        borderWidth: 1,
                        borderStyle: "solid",
                    }} height={"100%"}>
                        <Box component={"div"} paddingY={2}
                             sx={{
                                 backgroundColor: "primary.600",
                             }}>
                            <Typography level={"title-md"} sx={{color: "white"}}>
                                {bantouClubTexts.topicsLabel[languageSelector.lang]}
                            </Typography>
                        </Box>
                        <Stack paddingY={2} boxSizing={"border-box"} overflow={"auto"}>
                            {debates.map((value, index) => index < 15 &&
                                <>
                                    <Box component={"div"} className={"topic-title-component"} gap={2} display={"flex"}
                                         flexDirection={"column"} onClick={() => setCurrentTopic(value)}>
                                        <Typography textAlign={'start'} level={"title-md"}>
                                            {value[articleTitleField] || value.title}
                                        </Typography>
                                        <Stack direction={"row"} gap={1}>
                                            <Stack direction={"row"} gap={1}>
                                                <ThumbUpOutlined sx={{color: "primary.500"}}
                                                                 onClick={() => handleLike(value.id)}/>
                                                {value.like_count}
                                            </Stack>
                                            <Stack direction={"row"} gap={1}>
                                                <ShareRounded sx={{color: "primary.500"}}/>
                                                {value.share_count}
                                            </Stack>
                                            <Stack direction={"row"} gap={1}>
                                                <CommentOutlined sx={{color: "primary.500"}}/>
                                                {value.comment_count}
                                            </Stack>

                                        </Stack>
                                    </Box>
                                    <Divider/>
                                </>)}
                        </Stack>
                    </Stack>

                </Grid>
                <Grid xs={12} md={8} lg={8} display={{xs: currentTopic ? "block" : "none", md: "block"}}
                      sx={{
                          backgroundColor: "#f5ebde",
                          position: "relative",
                          height: "100%"
                      }}>

                    <Stack height={"100%"} sx={{
                        borderColor: "primary.100",
                        borderWidth: 1,
                        borderStyle: "solid"
                    }}>
                        <Box component={"div"} paddingY={2}
                             display={"flex"} paddingX={3}
                             alignItems={"center"}
                             justifyContent={"space-between"}
                             sx={{
                                 backgroundColor: "primary.600",
                             }}>

                            <Box component={"div"} display={{md: "none"}} sx={{cursor: "pointer"}}
                                 onClick={() => setCurrentTopic(null)}>
                                <ChevronLeftRounded/>
                            </Box>

                            {currentTopic && <Stack direction={{xs: "column", lg: "row"}} alignItems={"center"} gap={1}>
                                <Typography level={"title-md"} sx={{color: "white", textOverflow: "ellipsis"}}>
                                    {currentTopic?.title}
                                </Typography>

                                <Stack direction={"row"} gap={2} marginX={2.5}>

                                    <Link href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                                          target={"_blank"}>
                                        <FacebookSharp fontSize={"small"}/>
                                    </Link>

                                    <Link href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`}
                                          target={"_blank"}>
                                        <LinkedIn fontSize={"small"}/>
                                    </Link>

                                    <Link
                                        href={`https://x.com/share?text=${currentTopic?.title + " - Hopna Agency"}&url=${shareLink}`}
                                        target={"_blank"}>
                                        <X fontSize={"small"}/>
                                    </Link>
                                </Stack>
                            </Stack>}


                        </Box>

                        <Stack
                            sx={{
                                overflowY: "auto",
                                marginBottom: 8,
                                scrollbarWidth: "thin",
                                scrollbarColor: "var(--color-primary-50) transparent"
                            }}>
                            {loadingComments ? <LoadingComponent/> : debateComments.map(value =>
                                <BantouClubCommentComponent
                                    comment={value} isMyComment={userSelector.user?.id === (value.user as User).id}/>)}
                        </Stack>

                        <Box component={"form"} width={"100%"} height={"initial"} position={"absolute"}
                             alignItems={"center"}
                             display={"flex"} bottom={0} padding={2} gap={1} boxSizing={"border-box"}
                             onSubmit={handleSubmitComment}>

                            <Textarea size={"lg"} placeholder={"Enter your comment"} maxRows={3}
                                      value={comment} onChange={(event) => setComment(event.target.value)}
                                      sx={{
                                          width: "100%"
                                      }} required/>

                            <Button endDecorator={<SendRounded/>} type={"submit"}
                                    size={"lg"} loading={submittingComment}>
                                <Typography display={{xs: "none", md: "block"}} sx={{color: "inherit"}}>
                                    {bantouClubTexts.sendMessageLabel[languageSelector.lang]}
                                </Typography>

                            </Button>
                        </Box>

                    </Stack>
                </Grid>
            </Grid>
        </Box> :
        <Box component={"div"} height={"50vh"}>
            <LoadingComponent/>
        </Box>
}

export default BantouClubPage