import Article from "../../types/article";
import {Box, Stack} from "@mui/joy";
import "./styles.css"
import {ArticleComponent5, ArticleComponent6, ArticleComponent7} from "../ArticleComponent";
import {useAppSelector} from "../../hooks";
import {useEffect, useRef, useState} from "react";
import {translateToFr} from "../../api/lang";
import Category from "../../types/category";

type SectionsItemComponentProps = {
    category: Category,
    items: Article[]
}
const FeaturedCategoryItemComponent = (props: SectionsItemComponentProps) => {
    const langSelector = useAppSelector(state => state.lang)
    const categoryNameField = langSelector.lang === "fr" ? "name_fr" : "name"

    if (props.items?.length > 0)
        return <Box component={'div'} width={"100%"} display={"flex"} flexDirection={"column"} gap={2.5}>

            <Stack gap={1}>
                <Box component={"div"} className={"section-title-container"}>
                    {props.category?.[categoryNameField] || props.category?.name}
                </Box>
                <img className={"section-item-header-img"} src={props.items?.[0]?.images?.[0]?.[0]}/>
            </Stack>

            <Stack gap={2}>
                <ArticleComponent6 article={props.items?.[0]}/>
            </Stack>


            <Stack gap={4.5}>
                {
                    props.items?.map((value, index) => (index > 0 && index < 3) && <ArticleComponent7 article={value}/>)
                }
            </Stack>


        </Box>
}

export default FeaturedCategoryItemComponent