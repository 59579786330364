import {Outlet} from "react-router-dom";
import {Box} from "@mui/joy";
import {FooterAltComponent} from "../../components/Footer";

const AuthPage = () => {
    return <Box component={"div"} sx={{backgroundColor: "rgba(163,47,2,0.6)"}} width={"100%"}
                display={"flex"} flexDirection={"column"} height={{xs: "100%"}}>
        <Outlet/>
        <FooterAltComponent/>
    </Box>
}
export default AuthPage