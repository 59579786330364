import {Alert, Box, Button, Grid, Input, Stack, Textarea, Typography} from "@mui/joy";
import "./styles.css"

const AboutUsPage = () => {
    return <Box component={"div"} paddingX={{xs: "25px", lg: "15%"}} marginTop={10} paddingY={{xs: 4, lg: 10}}
                boxSizing={"border-box"} display={"flex"} flexDirection={"column"} gap={7}>

        <Typography level={"h2"}>
            About Hotep Pan-African News Agency (HOPNA)
        </Typography>

        <Stack>

            <Box component={"img"} width={"auto"} height={200} src={require("../../assets/images/logo.jpg")}
                 sx={{objectFit: "contain"}}/>

            <div className="container">
                <Typography level={"title-lg"} textAlign={"justify"} lineHeight={2}>
                    L’agence de presse panafricaine en abrégé « HOPNA »
                    basée à Genève est
                    spécialisée dans la diffusion
                    d’informations à travers une Cinquantaine de correspondants dans le monde. Elle produit des articles
                    et dépêches de presse à travers ces plateformes Web et Mobile(Afrique infos, HOPNA News agency…), Un
                    Magazine Business, des reportages audio-visuels, des films documentaires et dispose d’une expertise
                    pour la gestion de vos campagnes médiatiques, digitales et événementielles à travers un Live Évent
                    diffusé sur nos plateformes…mais aussi sur des chaînes partenaires.

                    Mais aussi d’un accompagnement médiatique pour vos litiges commerciaux et autres…

                    De l’information au départ de l’Afrique pour l’Afrique et par les Africains… dire pour bâtir des
                    ponts entre les peuples, les cultures et l’humanité…et non pour détruire, diviser ou opposer…telle
                    est notre leitmotiv.

                    N’hésitez pas à nous contacter pour vos suggestions et informations…</Typography>
            </div>

        </Stack>

    </Box>
}

export default AboutUsPage