import {SxProps} from "@mui/material";

const SxStyles: { [key: string]: SxProps } = {
    trendingNewsContainerOverlay: {
        boxSizing: "border-box",
        width: "100%",
        flexWrap: "wrap",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.40)",
        paddingX: {xs: "25px", lg: "10%"},
        paddingY: 2,
        gap: 1.5,
        alignItems: "end",
        display: "flex",
        position: "absolute",
        zIndex: 1,
    },
    text: {
        color: "white",

    },

}

export default SxStyles