import {Stack} from "@mui/joy";
import NavBar from "./NavBar";
import SectionBar from "./SectionBar";
import {useState} from "react";
import NavbarMenuDrawer from "../NavbarMenuDrawer";

const Appbar = () => {
    const [navbarMenuDrawerOpen, setNavbarMenuDrawerOpen] = useState(false)
    return <Stack position={"fixed"} width={"100%"} top={0} left={0} right={0} zIndex={3}>
        <NavBar navbarOpen={navbarMenuDrawerOpen}
                onNavbarToggle={() => setNavbarMenuDrawerOpen(prevState => !prevState)}/>
        <SectionBar/>
        <NavbarMenuDrawer visible={navbarMenuDrawerOpen} onMenuClose={() => setNavbarMenuDrawerOpen(false)}/>
    </Stack>
}

export default Appbar