import {List, ListItem, Stack, Typography} from "@mui/joy";
import styles from "./styles";
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {fetchCategories, setCategories} from "../../store/categorySlice";
import {useEffect, useState} from "react";
import {getCategories} from "../../api/category";

const SectionBar = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const currentRoute = location.pathname
    const langSelector = useAppSelector(state => state.lang)
    const categoriesSelector = useAppSelector(state => state.categories)
    const categoryNameField = langSelector.lang === "fr" ? "name_fr" : "name"

    useEffect(() => {
        getCategories()
            .then(value => dispatch(setCategories(value)))
            .catch(() => {
            })
    }, [])

    return <Stack sx={styles.categoryBarContainer} display={{
        xs: "none", sm: "flex"
    }}>
        {categoriesSelector.categories?.map(value =>
            <a className={"text-normal"} href={`/categories/${value.id}`}>
                <Typography sx={{
                    color: currentRoute === "/" + value.name ? "#A33E02" : "initial",
                    fontWeight: currentRoute === "/" + value.name ? "bold" : "initial",
                }} level={"body-sm"}>
                    {value[categoryNameField] || value.name}
                </Typography>
            </a>)}
    </Stack>

}

export default SectionBar