import {Outlet, useNavigate} from "react-router-dom"
import Appbar from "../../components/Appbar";
import "../page-styles.css"
import FooterComponent from "../../components/Footer";
import {Box} from "@mui/joy";
import LiveTVComponent from "../../components/LiveTV";
import {useAppSelector} from "../../hooks";
import {useEffect, useState} from "react";


const Main = () => {
    const articleSlice = useAppSelector(state => state.index)
    const [webTvElementPosition, setWebTvElementPosition] = useState("fixed")


    return (
        <div className={"app-container"}>
            <Appbar/>
            <Box component={"div"} width={"100%"} height={"max-content"} position={"relative"}>
                {!articleSlice.isLoading &&
                    <LiveTVComponent position={webTvElementPosition}/>}
                <Outlet/>
            </Box>
            {!articleSlice.isLoading && <>
                <FooterComponent
                    onVisibilityChange={visible => setWebTvElementPosition(visible ? "absolute" : "fixed")}/>
            </>}

        </div>
    )

}

export default Main