import {extendTheme} from '@mui/joy/styles';

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: "#A36102",
                    100: "#A35702",
                    200: "#A35202",
                    300: "#A34D02",
                    400: "#A34802",
                    500: "#A34302",
                    600: "#A33E02",
                    700: "#A33902",
                    800: "#A33402",
                    900: "#A32F02",
                }
            }
        },
        dark: {
            palette: {
                primary: {
                    50: "#BA7641",
                    100: "#B56D35",
                    200: "#B16328",
                    300: "#AC5A1B",
                    400: "#A8510F",
                    500: "#A34302",
                    600: "#934102",
                    700: "#8B3D02",
                    800: "#823A02",
                    900: "#7A3602",
                }
            }
        }
    },
    components: {
        JoyButton: {
            defaultProps: {
                color: "primary",
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "white",
                    fontSize: "x-large"
                }
            }
        }
    }
})

export default theme