export const validateName = (name: string) => {
    let regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,30}$/u
    return regex.test(name)
}

export const validateEmail = (email: string) => {
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return regex.test(email)
}

export const validateUsername = (username: string) => {
    if (['_', '.'].some(value => value === username.substring(0, 1)) || ['_', '.'].some(value => value === username.substring(username.length - 1)))
        return false
    let regex = /^[a-zA-Z0-9_.]{4,10}$/
    return regex.test(username)
}

