import {Alert, Box, Button, Grid, Input, Stack, Textarea, Typography} from "@mui/joy";
import "./styles.css"

const TermsOfUsePage = () => {
    return <Box component={"div"} paddingX={{xs: "25px", lg: "15%"}} marginTop={10} paddingY={{xs: 4, lg: 10}}
                boxSizing={"border-box"} display={"flex"} flexDirection={"column"} gap={7}>

        <Typography level={"h2"}>
            Conditions d'utilisation
        </Typography>

        <Stack>

            <Box component={"img"} width={"auto"} height={200} src={require("../../assets/images/logo.jpg")}
                 sx={{objectFit: "contain"}}/>

            <div className="container">
                <Typography level={"title-lg"} textAlign={"justify"} lineHeight={2}>Bienvenue sur le site de Hopna
                    ("nous", "notre" ou "nos"). En accédant à notre site web, vous acceptez de respecter les présentes
                    conditions d'utilisation. Veuillez les lire attentivement avant de continuer à utiliser notre site.

                    <br/> 1. Acceptation des Conditions
                    En accédant et en utilisant notre site, vous acceptez sans réserve les présentes conditions
                    d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site. <br/>

                    <br/>2. Utilisation du Contenu
                    Tout le contenu disponible sur notre site, y compris mais sans s'y limiter, les articles, les
                    images, les vidéos et les graphiques, est protégé par les lois sur la propriété intellectuelle. Vous
                    pouvez utiliser le contenu uniquement pour votre usage personnel et non commercial, à condition de
                    ne pas modifier le contenu et de conserver toutes les mentions de droits d'auteur et autres mentions
                    de propriété.

                    <br/> <br/>3. Inscription et Sécurité
                    Certaines parties de notre site peuvent nécessiter une inscription. Vous êtes responsable de
                    maintenir la confidentialité de votre identifiant et de votre mot de passe, ainsi que de toutes les
                    activités qui se déroulent sous votre compte. Vous acceptez de nous informer immédiatement de toute
                    utilisation non autorisée de votre compte.

                    <br/> <br/> 4. Politique de Confidentialité
                    Votre vie privée est importante pour nous. Notre politique de confidentialité, qui décrit comment
                    nous recueillons, utilisons et protégeons vos informations personnelles, est incorporée dans ces
                    conditions d'utilisation par référence. Veuillez la lire attentivement.

                    <br/> <br/>5. Limitation de Responsabilité
                    Nous nous efforçons de fournir des informations précises et à jour, mais nous ne garantissons pas
                    l'exactitude, l'exhaustivité ou l'actualité des informations disponibles sur notre site. Nous ne
                    sommes pas responsables des erreurs ou omissions, ni des pertes ou dommages résultant de
                    l'utilisation des informations disponibles sur notre site.

                    <br/> <br/>6. Liens vers des Sites Tiers
                    Notre site peut contenir des liens vers des sites web de tiers. Ces liens sont fournis à titre de
                    commodité uniquement et nous n'approuvons ni ne sommes responsables du contenu de ces sites tiers.

                    <br/> <br/>7. Modifications des Conditions d'Utilisation
                    Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les
                    modifications seront publiées sur cette page et entreront en vigueur immédiatement. Votre
                    utilisation continue de notre site après la publication des modifications constitue votre
                    acceptation de celles-ci.

                    <br/> <br/>8. Communication entre Utilisateurs
                    Vous avez la possibilité de communiquer avec d'autres utilisateurs sur notre site. En utilisant
                    cette fonctionnalité, vous acceptez de respecter les règles suivantes :

                    Respect et Courtoisie : Vous devez toujours communiquer de manière respectueuse et courtoise. Toute
                    forme de harcèlement, de discours haineux, de discrimination, ou d'intimidation est strictement
                    interdite.
                    Contenu Approprié : Vous ne devez pas publier de contenu illégal, offensant, diffamatoire, obscène,
                    ou qui viole les droits de tiers. Cela inclut, mais ne se limite pas à, le partage de contenu
                    protégé par des droits d'auteur sans autorisation.
                    Protection des Données : Vous ne devez pas divulguer d'informations personnelles sans le
                    consentement de l'individu concerné. Cela inclut les adresses e-mail, les numéros de téléphone, les
                    adresses résidentielles, etc.
                    Respect de la Loi : Vous devez vous conformer à toutes les lois et réglementations applicables lors
                    de vos communications sur notre site.
                    <br/> <br/>9. Droit Applicable
                    Ces conditions d'utilisation sont régies par les lois en vigueur dans le pays où nous sommes
                    établis. Tout litige relatif à ces conditions d'utilisation sera soumis à la juridiction exclusive
                    des tribunaux compétents de cette juridiction.

                    <br/> <br/>10. Contact
                    Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à
                    info@hopna-agency.media.

                    <br/> <br/> <b>Dernière mise à jour : 12/07/2024</b>
                </Typography>
            </div>

        </Stack>

    </Box>
}

export default TermsOfUsePage