import {Alert, Box, Button, Grid, Input, Stack, Textarea, Typography} from "@mui/joy";
import {
    DoneOutline,
    DoneRounded,
    EmailOutlined,
    EmailRounded,
    LocationOnRounded,
    PhoneRounded
} from "@mui/icons-material";
import {post} from "../../api";
import {HOST} from "../../constants";
import {useState} from "react";
import {useAppSelector} from "../../hooks";
import contactUsTexts from "./contactUsTexts";
import authTexts from "../Account/authTexts";

const ContactUsPage = () => {
    const langSelector = useAppSelector(state => state.lang)
    const [formData, setFormData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        post(HOST + "/contact-us", formData, {})
            .then(() => {
                setLoading(false)
                setFormSubmitted(true)
                setFormData({})
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const handleValueChange = (key, value) => {
        setFormData(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    return <Box component={"div"} paddingX={{xs: "25px", lg: "15%"}} marginTop={12} paddingY={{xs: 4, lg: 10}}
                boxSizing={"border-box"}>

        <Grid container boxSizing={"border-box"}>
            <Grid xs={12} md={6} sm={8} rowSpacing={4}
                  sx={{
                      paddingX: {
                          sm: 4,
                          lg: 10
                      }
                  }}>

                <Stack gap={1}>
                    <Typography textAlign={"start"} level={"h2"}>
                        Let's talk
                    </Typography>

                    <Typography textAlign={"start"}>
                        {contactUsTexts.contactUsTitle[langSelector.lang]}
                    </Typography>

                </Stack>

                <Box component={"form"} display={"flex"} flexDirection={"column"} gap={2.5} marginY={3}
                     onSubmit={handleSubmit}>
                    <Input placeholder={authTexts.fullNameLabel[langSelector.lang]}
                           type={"text"} size={"lg"}
                           onChange={(event) => {
                               handleValueChange("name", event.target.value)
                           }} name={"name"} value={formData.name} fullWidth required/>

                    <Input placeholder={authTexts.emailAddressLabel[langSelector.lang]} type={"email"} size={"lg"}
                           onChange={(event) => {
                               handleValueChange("email", event.target.value)
                           }} name={"email"} value={formData.email} fullWidth required/>

                    <Input placeholder={contactUsTexts.serviceRequestQuestion[langSelector.lang]} type={"text"}
                           size={"lg"}
                           onChange={(event) => {
                               handleValueChange("reason", event.target.value)
                           }} name={"reason"} value={formData.reason} fullWidth required/>

                    <Textarea placeholder={contactUsTexts.contactUsMessagePlaceholder[langSelector.lang]}
                              name={"message"}
                              size={"lg"} minRows={3} value={formData.message}
                              onChange={(event) => {
                                  handleValueChange("message", event.target.value)
                              }}
                              sx={{
                                  width: "100%"
                              }} required/>
                    {formSubmitted ?
                        <Alert variant={"soft"} color={"success"}
                               startDecorator={<DoneRounded sx={{color: "inherit"}}/>}>
                            {contactUsTexts.contactUsSuccessLabel[langSelector.lang]}
                        </Alert> :
                        <Button loading={loading} type={"submit"}
                                size={"lg"}>{contactUsTexts.contactUsSubmitLabel[langSelector.lang]}</Button>}
                </Box>

            </Grid>

            <Grid display={{xs: "none", sm: "block"}} md={6} sm={4}
                  sx={{
                      paddingX: {
                          md: 4,
                          lg: 10
                      }
                  }}>
                <Stack gap={3} justifyContent={"start"}>
                    <Box component={"img"} width={"100%"} height={250} src={require("../../assets/images/logo.jpg")}
                         sx={{objectFit: "contain"}}/>

                    <Stack gap={1}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <LocationOnRounded sx={{color: "primary.500"}}/>
                            <Typography textAlign={"start"} level={"body-sm"}>
                                Avenue des Grandes Communes 8,1213 Petit-Lancy, Genève, Suisse
                            </Typography>
                        </Stack>

                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <EmailRounded sx={{color: "primary.500"}}/>
                            <a href={"mailto:contact@hopna-agency.media"}>
                                <Typography textAlign={"start"}
                                            level={"body-sm"}>
                                    contact@hopna-agency.media
                                </Typography>
                            </a>
                        </Stack>

                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <PhoneRounded sx={{color: "primary.500"}}/>
                            <Typography textAlign={"start"} level={"body-sm"}>
                                +41763034834
                            </Typography>
                        </Stack>
                    </Stack>

                </Stack>
            </Grid>

        </Grid>

    </Box>
}

export default ContactUsPage