export const articleTexts = {
    authorLabel: {
        en: "By",
        fr: "Auteur: "
    },
    publishedDateLabel: {
        en: "Published",
        fr: "Publication"
    },
    nextArticleReadLabel: {
        en: "Up Next",
        fr: "à voir aussi"
    },
    moreStoriesLabel: {
        en: "More stories",
        fr: "Plus d'articles"
    },
    readFullArticle:{
        en:"Read full article",
        fr:"Lire la suite"
    },
    shareArticleText:{
        en:"Check this article i found on HOPNA",
        fr:"Regarde cet article decouvert sur HOPNA"
    }
}

export default articleTexts