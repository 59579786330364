import {Alert, Box, Button, Grid, Input, Stack, Textarea, Typography} from "@mui/joy";
import "./styles.css"

const PrivacyPolicyPage = () => {
    return <Box component={"div"} paddingX={{xs: "25px", lg: "15%"}} marginTop={10} paddingY={{xs: 4, lg: 10}}
                boxSizing={"border-box"} display={"flex"} flexDirection={"column"} gap={7}>

        <Typography level={"h2"}>
            Politique de Confidentialité
        </Typography>

        <Stack>

            <Box component={"img"} width={"auto"} height={200} src={require("../../assets/images/logo.jpg")}
                 sx={{objectFit: "contain"}}/>

            <div className="container">
                <Typography level={"title-lg"} textAlign={"justify"} lineHeight={2}>

                    Chez Hopna ("nous", "notre" ou "nos"), nous nous engageons à protéger votre vie privée. Cette
                    politique de confidentialité explique comment nous recueillons, utilisons, partageons et protégeons
                    vos informations personnelles lorsque vous utilisez notre site web.

                    <br/> <br/>1. Collecte des Informations
                    Nous pouvons collecter différents types d'informations lorsque vous utilisez notre site, notamment :

                    Informations Personnelles : Nom, adresse e-mail, numéro de téléphone, adresse postale, et toute
                    autre information que vous nous fournissez volontairement lors de votre inscription ou de
                    l'utilisation de nos services.
                    Informations de Connexion : Adresse IP, type de navigateur, fournisseur d'accès Internet, pages de
                    renvoi/sortie, et horodatage.
                    Informations sur l'Utilisation : Données sur votre activité sur notre site, y compris les pages
                    visitées, le temps passé sur le site, et les clics.
                    <br/> <br/>2. Utilisation des Informations
                    Nous utilisons les informations collectées pour :

                    Fournir, gérer et améliorer nos services.
                    Communiquer avec vous, répondre à vos questions et vous fournir des informations sur nos services.
                    Personnaliser votre expérience sur notre site.
                    Analyser l'utilisation de notre site pour améliorer nos services et comprendre les tendances des
                    utilisateurs.
                    Se conformer à nos obligations légales et réglementaires.
                    <br/> <br/>3. Partage des Informations
                    Nous ne vendons pas, n'échangeons pas et ne louons pas vos informations personnelles à des tiers
                    sans votre consentement, sauf dans les cas suivants :

                    Fournisseurs de Services : Nous pouvons partager vos informations avec des tiers qui nous
                    fournissent des services, tels que l'hébergement de notre site, l'analyse des données, le traitement
                    des paiements et le service à la clientèle. Ces tiers sont tenus de protéger vos informations et de
                    ne les utiliser que pour fournir les services spécifiés.
                    Obligations Légales : Nous pouvons divulguer vos informations si la loi l'exige ou si nous croyons
                    de bonne foi que cette divulgation est nécessaire pour se conformer à une procédure judiciaire, une
                    ordonnance de tribunal ou une demande gouvernementale.
                    Protection de Nos Droits : Nous pouvons divulguer vos informations pour protéger nos droits, notre
                    propriété ou notre sécurité, ainsi que ceux de nos utilisateurs ou du public.
                    <br/> <br/>4. Sécurité des Informations
                    Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger vos informations contre
                    tout accès, utilisation ou divulgation non autorisés. Cependant, aucune méthode de transmission sur
                    Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir la
                    sécurité absolue de vos informations.

                    <br/> <br/>5. Vos Droits
                    En fonction de votre juridiction, vous pouvez avoir les droits suivants concernant vos informations
                    personnelles :

                    Accès et Correction : Vous pouvez accéder à vos informations personnelles et les corriger si elles
                    sont inexactes ou incomplètes.
                    Suppression : Vous pouvez demander la suppression de vos informations personnelles, sous réserve de
                    certaines exceptions légales.
                    Opposition et Limitation : Vous pouvez vous opposer à ou limiter le traitement de vos informations
                    personnelles dans certaines circonstances.
                    Pour exercer ces droits, veuillez nous contacter à [adresse e-mail].

                    <br/> <br/>6. Cookies et Technologies Similaires
                    Nous utilisons des cookies et d'autres technologies similaires pour collecter des informations sur
                    votre utilisation de notre site et pour personnaliser votre expérience. Vous pouvez configurer votre
                    navigateur pour refuser les cookies ou pour vous avertir lorsque des cookies sont envoyés.
                    Cependant, certaines fonctionnalités de notre site peuvent ne pas fonctionner correctement sans
                    cookies.

                    <br/> <br/>7. Modifications de cette Politique
                    Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Les modifications
                    seront publiées sur cette page et entreront en vigueur immédiatement. Nous vous encourageons à
                    consulter régulièrement cette page pour vous tenir informé de nos pratiques en matière de
                    confidentialité.

                    <br/> <br/>8. Contact
                    Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité,
                    veuillez nous contacter à info@hopna-agency.media

                    <br/> <br/> <b>Dernière mise à jour : 12/07/2024</b>
                </Typography>
            </div>

        </Stack>

    </Box>
}

export default PrivacyPolicyPage