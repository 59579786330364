const homeTexts = {
    featuredVideosLabel: {
        en: "Featured Videos",
        fr: "Vidéos en vedette"
    },
    featuredBantouClubTopicsLabel: {
        en: "Featured Topics",
        fr: "Thématiques en vedette"
    },
    topicOfTheDayLabel: {
        en: "Topic of the day",
        fr: "Thématique du jour"
    },
    seeMoreLabel: {
        en: "See more",
        fr: "Voir plus"
    },
    latestNewsLabel: {
        en: "Breaking News",
        fr: "Flash Info"
    },
    featuredStoriesLabel: {
        en: "Featured Stories",
        fr: "Articles en vedette"
    },
    featuredSectionsLabel: {
        en: "Featured sections",
        fr: "Categories en vedette"
    },
    readTrendingNewsLabel: {
        en: "Read now",
        fr: "Lire l'article"
    },
    commented:{
        en:" Commented",
        fr:" a Commenté"
    }
}
export default homeTexts