const bantouClubTexts = {
    bantouClubDescription: {
        en: "Find out debates on the local and worldwide actualities, view, share and exchange point of view with other users",
        fr: "Decouvrez des debats sur les actualités nationales et internationales, partagez et echangez de point de vu avec d'autres utilisateurs."
    },
    topicsLabel: {
        en: "Topics",
        fr: "Thématiques"
    },
    sendMessageLabel: {
        en: "Send",
        fr: "Envoyer"
    }
}

export default bantouClubTexts