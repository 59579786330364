const footerTexts = {
    followUsLabel: {
        en: "Follow us",
        fr: "Suivez-nous"
    },
    aboutUs:{
      en:"About us",
      fr:"A propos de nous"
    },
    cookieSettings: {
        en: "Cookie settings",
        fr: "Cookies"
    }
}

export default footerTexts