const subscribeTexts = {
    redirectionText: {
        en: "Please hold on while you are being redirected...",
        fr: "Redirection en cours, veuillez patienter..."
    },
    redirectionErrorText: {
        en: "An error has occurred, hold on while you are being redirected...",
        fr: "Une erreur est survenue, redirection en cours..."
    }
}
export default subscribeTexts