const myAccountTexts = {
    myAccountTitle: {
        en: "My Account",
        fr: "Mon Compte"
    },
    myAccountDescription: {
        en: "View and edit your personal and account info",
        fr: "Modifiez vos informations personelles"
    },
    newPasswordLabel: {
        en: "New Password",
        fr: "Nouveau mot de passe"
    },
    confirmNewPasswordLabel: {
        en: "Confirm new password",
        fr: "Confirmer le mot de passe"
    },
    personalInfoLabel: {
        en: "Personal Info",
        fr: "Info personelles"
    },
    updateProfileLabel: {
        en: "Update profile",
        fr: "Modifier le profile"
    },
    updatePasswordLabel: {
        en: "Update password",
        fr: "Modifier le mot de passe"
    },
    subscriptionHistory: {
        en: "Subscription history",
        fr: "Historique de souscription"
    },
    passwordUpdateSuccessLabel: {
        en: "Password updated successfully",
        fr: "Mot de passe modifié avec succès"
    },
    profileUpdateSuccessLabel: {
        en: "Profile updated successfully",
        fr: "Informations modifiées avec succès"
    },
    subscriptionTitle: {
        en: "Subscription",
        fr: "Souscription"
    },
    noActiveSubscription: {
        en: "No active subscription",
        fr: "Aucune souscription active"
    },
    subscribeLabel: {
        en: "Subscribe",
        fr: "Souscrire"
    },
    upgradeSubscriptionLabel:{
        en:"Upgrade",
        fr:"Mettre à niveau"
    }
}

export default myAccountTexts